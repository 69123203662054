import React, {useState, useEffect} from 'react';
import { Outlet } from "react-router-dom";

import { Wrapper, Content, MenuButton, ExitButton, ExitText } from '../styles.js';
import { Sidebar } from '../sideBar.js';
import { LogoutIcon } from '../sideBarStyles.js';
import logo from '../../src/assets/images/logo.png'



export default function Root({isSelected}) {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1000);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <Wrapper>
            {isMobile ? (
                <div style={{display:'flex', alignItems: 'center', width:'100%', height:'50px', backgroundColor:'#275224', padding:5}}>                
                <img src={logo} width={40} alt='logo'/>
                    <ExitButton to={`/`}>
                        <LogoutIcon size={15}/>
                        <ExitText className='medium'>Sair</ExitText>
                    </ExitButton>
                </div>
            ) : (
                <Sidebar isSelected={isSelected} />
            )}
                <Content><Outlet /></Content>           
        </Wrapper>
    );
}



 
