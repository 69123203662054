import styled from  'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 12px;

    table {
        border-radius: 5px;
        background-color: rgb(232, 232, 232);
        width: 150%;
        align-items: center;
        justify-content: center;
        margin-bottom: 2%;

        @media screen and (max-width: 844px){
            width:120%
        }
    }
    
    td {
        padding: 10px;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;      
    }
    
    th {
        padding: 10px;
        background-color: #ddd;
        font-weight: bold;
    }

`