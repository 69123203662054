import styled, { css } from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
    width:100% - 40px;
    height:100% - 50px;
    display:flex;
    flex-direction:column;
    justify:content:center;
    padding: 80px 70px 0px 70px;

    @media screen and (max-width:1919px){
        font-size:13px;
    }

    @media screen and (max-width:1600px){
        padding: 50px 60px 50px 70px;
        height:100% -20px;
    }

    @media (max-width: 1000px){
        padding:20px;
        height:100%;
        width:89%;
    }
`

export const Title = styled.div`
    font-size:40px;
    color:#275224;
    margin-bottom:50px;

    @media (max-width: 1000px){
        font-size:30px;
        margin-bottom:10px;
    }
`

export const FilterContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap:30px;
`

export const ButtonContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    gap:30px;
`

export const FilterProductorsContainer = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    gap:30px;
    margin:50px 0px;
`

export const InputTitle = styled.div`
    color:#275224;
    width:100%;
`

export const StyledDatePicker = styled(DatePicker)`
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    font-size:16px;
    margin-top:5px;
    padding-left:10px;
    width:100%;
    color:#575757;
    ${p=> p.error?css`
    border: solid;
    border-width:2px;
    border-color:#EE4245;
    `:''}

    @media (max-width:1400px){
        font-size:16px;
    }
`

export const Select = styled.select`

    -webkit-appearance: none;

    color: #575757;
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px 0 0 5px;

    ${p => p.error?css`
    border:solid;
    border-width: 2px 0px 2px 2px;
    border-color: #EE4245;
    `
    :
    ''
    }

    font-size:20px;
    margin-top:5px;
    padding-left:10px;  
    width:100%;
    outline:none;

    @media (max-width:1400px){
        font-size:16px;
    }
`

export const FakeContainer = styled.div`
    display:flex;
    align-items:center;
`

export const FakeBorder = styled.div`
width:10px;
background-color:#D9D9D9;
height:43px;
margin-top:5px;
border-radius:0 5px 5px 0;

${p => p.error?css`
    border:solid;
    border-width: 2px 2px 2px 0px;
    border-color: #EE4245;
    height:41px;
    `
    :
    ''
    }
`

export const SelectContainer = styled.div`
    display:flex;
`

export const ProductorSelector = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-between;
`

export const ProductorsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height:35px;
    height: auto;
    flex-wrap: wrap;
    // overflow-y: scroll;
    padding: 5px;
    margin-top: 5px;
    background-color: #F7F7F7;
    border-radius: 5px 0px 0px 5px;
`

export const SelectedProductors = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E6E6E6;
    width: fit-content;
    height: 20px;
    padding: 2px 8px;
    margin: 2px;
    border-radius: 50px;
    color: #575757;
`

export const GenerateButton = styled.button`
    height:45px;
    border:none;
    border-radius:5px;
    font-size:20px;
    margin-top:20px;
    background-color:#275224;
    width:100%;
    cursor:pointer;
    color:#FFF;
`
