import Cookies from 'js-cookie';

export function setBatchToUpdate(data){
    return(
        {
            type:'@batch/SET_BATCH_UPDATE',
            payload: data
        }
    )
}

export function setBatchToEvaluation(data){
    return({
        type:'@batch/BATCH_EVALUATION',
        payload:data
    })
}

export function resetBatch(){
    return({
        type:'@batch/RESET'
    })
}

export const logout = () => {

    const cookies = Cookies.get();
  
    for (const cookie in cookies) {
        Cookies.remove(cookie);
    }
        
    return {
      type: '@batch/LOGOUT',
    };
  };