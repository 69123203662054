import styled from 'styled-components';
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
    width: 100vw;
    height:100vh;
    display:flex;
    flex-direction:row;
    overflow-x:hidden;

    @media (max-width: 1000px){
        flex-direction:column;
        padding:0;
        height:100%;
    }
`

export const Content = styled.div`
    position: relative;
    height: 100%;    
    width:92%;
    margin-left:7%;
    
    @media (max-width: 1000px) {
        border: 0;
        padding: 100px 20px 20px;
        height: 100%;
    }

    @media (max-width: 1000px){
        padding:0;
        margin-left:0;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`

export const ExitButton = styled(Link)`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-end;
  width:100vw;
  height:20px;
  padding:10px;
`

export const ExitText = styled.div`
    color:#FFF;
    font-size:13px;
    margin-right:5px;
`
