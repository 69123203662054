import React, {useState} from 'react';
import {cookies, localKeys} from '../Cookies/cookiesAndLocalKeys';

export default function PolicyBody() {

    let heightView = window.innerHeight

    const Item = ({ item, handleClick }) => {
        const [expanded, setExpanded] = useState(false);
      
        const handleExpand = () => {
          setExpanded(!expanded);
        };
      
        return (
          <li className="item">
            <div className="header" onClick={handleExpand}>
              <span className="name">{item.name}</span>
            </div>
            
              <ul className="details">
                <li>Objetivo: {item.objective}</li>
                <li>Duração: {item.duration}</li>
              </ul>
            
          </li>
        );
      };

      const List = ({ items }) => {
        return (
          <ul className="list">
            {items.map((item) => (
              <Item key={item.name} item={item} />
            ))}
          </ul>
        );
      };



    return (
        <div style={{overflowY:'scroll', overflowX:'hidden', height: parseInt(heightView) >= 903 ? '30vh' :'50vh', fontFamily: 'sans-serif'}}>
            <h5><strong>Atualizada em 04/03/2024</strong></h5>
            <p>
                <strong>IMPORTANTE:</strong> AO USAR O NOSSO SITE E/OU NOS FORNECER DADOS
                PESSOAIS, VOCÊ DÁ SEU CONSENTIMENTO EXPLÍCITO PARA QUE TODOS OS
                DADOS PESSOAIS ENVIADOS SEJAM PROCESSADOS POR NÓS DA MANEIRA E
                PARA AS FINALIDADES DESCRITAS NA POLÍTICA DE PRIVACIDADE A
                SEGUIR E EM CONFORMIDADE COM AS LEIS E OS REGULAMENTOS EM VIGOR
                SOBRE DADOS PESSOAIS.
            </p>

            <h4><strong>RESPEITO À PRIVACIDADE</strong></h4>
            <p>
                Estamos comprometidos com a proteção e o respeito à privacidade.
                Esta Política estabelece a base sobre a qual todos os dados
                pessoais que coletamos de você, ou que você nos fornece, serão
                processados no nosso site. Nós guardamos certas informações
                básicas quando você visita nosso site e reconhecemos a
                importância de mantê-las seguras e de informar você sobre o que
                faremos com elas. Você pode optar por nos fornecer informações
                consideradas dados pessoais. O termo “dados pessoais”, conforme
                usado nesta Política, refere-se a informações como seu nome,
                endereço de e-mail, endereço de correspondência, número de
                telefone ou qualquer outro dado que possa ser usado para
                identificar você
            </p>

            <h4><strong>
                INFORMAÇÕES SOBRE O RESPONSÁVEL PELO TRATAMENTO DE DADOS
            </strong></h4>
            <p>
                Este site é propriedade da Brazil Beef Quality. O responsável
                pelo tratamento dos seus dados pessoais varia dependendo da
                natureza da sua interação com o site.
            </p>

            <h4><strong>SEGURANÇA E CONFIDENCIALIDADE DOS DADOS</strong></h4>
            <p>
                Nós levamos muito a sério a segurança e a confidencialidade dos
                seus dados pessoais. Utilizamos técnicas modernas de
                armazenamento e segurança de dados para proteger suas
                informações pessoais contra acesso não autorizado, uso ou
                divulgação indevidos, modificação não autorizada, destruição
                ilegal ou perda acidental. Todos os processadores de dados
                pessoais e quaisquer serviços terceirizados envolvidos no
                processamento de suas informações pessoais são obrigados a
                respeitar a confidencialidade das suas informações.
            </p>

            <p>
                Protegemos seus dados pessoais em trânsito usando protocolos de
                criptografia, como a segurança de camada de transporte (TLS,
                sigla em inglês). Usamos sistemas de computador com protocolos
                de acesso limitado, localizados em instalações que usam medidas
                de segurança físicas, eletrônicas e processuais para proteger a
                confidencialidade e a segurança das informações transmitidas a
                nós. Mantemos normas rígidas de segurança para evitar qualquer
                acesso não autorizado.
            </p>

            <h4><strong>NOTIFICAÇÃO DE MUDANÇAS NESTA POLÍTICA</strong></h4>
            <p>
                Estamos continuamente melhorando e adicionando novas
                funcionalidades e recursos a este site, além de melhorar nossos
                serviços que já existem e adicionar outros serviços. Por causa
                dessas contínuas mudanças e de mudanças na lei e na natureza da
                tecnologia, nossos procedimentos relacionados a dados são
                alterados de tempos em tempos. Quando for necessário alterar
                nossa Política, colocaremos as alterações nesta página para que
                você fique sempre informado sobre quais informações coletamos e
                como as utilizamos.
            </p>

            <h4><strong>SCRIPT ATIVO OU JAVASCRIPT</strong></h4>
            <p>
                Usamos script ativo para melhorar a funcionalidade do nosso
                site. A tecnologia de script ativo permite que o site apresente
                para você as informações de forma mais rápida. O site nunca usa
                essa tecnologia para instalar softwares no seu computador nem
                para coletar informações não autorizadas.
            </p>

            <p>
                O script ativo ou JavaScript deve estar ativado no navegador
                para que algumas partes do site funcionem de modo correto. A
                maioria dos navegadores permite que esse recurso seja ativado ou
                desativado em sites específicos. Consulte os documentos de ajuda
                do navegador para aprender a ativar o script nos sites que você
                desejar
            </p>

            <h4><strong>USO DE INFORMAÇÕES PESSOAIS</strong></h4>
            <p>
                As informações pessoais que são fornecidas por você no site são
                usadas apenas para definir seu papel de usuário, proteger o
                acesso à sua conta e permitir o contato e envio de informações.
                Algumas informações coletadas para os propósitos acima definidos
                são (algumas informações são opcionais): Nome, e-mails,
                telefones, Estado, Cidade, endereço e outros.
            </p>

            <p>
                Suas informações são coletadas, armazenadas e utilizadas apenas
                para os objetivos pelos quais você enviou essas informações.
                Guardamos as informações apenas pelo tempo necessário para
                cumprir com esses objetivos ou com qualquer outro objetivo
                válido aplicável.
            </p>

            <p>
                As informações fornecidas na sua solicitação ou formulário só
                podem ser acessadas por processadores envolvidos no
                processamento da informação e/ou por técnicos que realizam
                tarefas relacionadas com a operação e manutenção do sistema. Não
                fornecemos suas informações pessoais a mais ninguém a não ser
                que <strong>(1)</strong> isso seja necessário para fornecer o serviço que você
                solicitou e você seja informado disso; <strong>(2)</strong> acreditemos de boa fé
                que a divulgação de determinada informação seja necessária para
                cumprir com quaisquer leis e normas aplicáveis; <strong>(3)</strong> isso seja
                solicitado por alguma autoridade; <strong>(4)</strong> isso seja necessário para
                detectar e prevenir fraude por razões técnicas ou de segurança
                ou <strong>(5)</strong> isso seja necessário para que outros usuários posso
                identifica-lo em operações que necessitem vincular informações à
                sua pessoa e/ou propriedade. Ao usar este site, você concorda
                que suas informações pessoais sejam divulgadas para terceiros
                apenas em cumprimento das finalidades citadas acima. Em nenhuma
                circunstância, as informações serão vendidas, alugadas ou
                comercializadas.
            </p>

            <p>
                Informações como características de propriedades, modo de
                atuação relacionado ao trabalho, informações sobre animais,
                carcaças e informações similares não
            </p>

            <h4><strong>USO DE COOKIES E TECNOLOGIAS SIMILARES</strong></h4>
            <p>
                Há vários tipos de cookies que realizam funções diferentes e de
                modo geral tornam melhor sua experiência no site. Seguem abaixo
                exemplos do nosso uso de cookies e tecnologias similares.
            </p>
            <List items={cookies.concat(localKeys)}/>
            <p>
                Para informações mais específicas entre em contato pelo e-mail <strong>contato@brazilbeefquality.com</strong>
            </p>
        </div>
    );
}