import React, { useState, useEffect } from 'react';
import { Container, Title, Select, FakeBorder, ButtonContainer} from './styles';
import { FilterContainer } from './styles';
import { StyledDatePicker } from './styles';
import { InputTitle } from './styles';

import API from "../../services/API";
import pt from 'date-fns/locale/pt';
import { format} from 'date-fns';
import { SelectContainer } from './styles';
import { ProductorSelector } from './styles';
import { ProductorsContainer } from './styles';
import { SelectedProductors } from './styles';
import { FakeContainer } from './styles';
import { FilterProductorsContainer } from './styles';
import { GenerateButton } from './styles';
import Constants from './constants';
import downloadCustomExcel from '../../components/DownloadExcel';
import Cookies from 'js-cookie';


export default function Report(){
    
    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);
    const [errors, setErrors] = useState('');
    const [productors, setProductors] = useState([]);
    const [productor, setProductor] = useState([]);
    const [shouldDownload, setShouldDownload] = useState(false);
    const [period, setPeriod] = useState(0);
    const [processment_plant, setProcessment_plant] = useState();

    const companyId = Cookies.get('sheep-company-id');

    const [periods, setPeriods] = useState([
        "Abate", 
        "Avaliação", 
    ]);

    useEffect(()=>{
        API.get('processmentPlants')
        .then((response) => {
            const processmentPlant = response.data.filter(plant=>plant.fridge.company_id === parseInt(companyId))
            setProcessment_plant(processmentPlant[0].id)
        })},[]);

        useEffect(() => {
            const fetchData = async () => {
                if (initialDate && finalDate) {
                    try {
                        const data = {
                            initial: `${format(initialDate, 'yyyy-MM-dd')} 00:00:00`,
                            final: `${format(finalDate, 'yyyy-MM-dd')} 23:59:59`,
                            processmentPlantId: processment_plant,
                            dateAbout: parseInt(period),
                        };
        
                        const productorsList = await API.post('productors/bydaterange', data);
                            setProductors(productorsList.data)
                    } catch (error) {
                        console.error("Error fetching data: ", error);
                    }
                }
            };
        
            fetchData();
        }, [initialDate, finalDate, processment_plant, period]);

    const handleFormatInitialDate = (date) => {
        if(date){ 
            setInitialDate(date);
        }
    }

    const handleFormatFinalDate = (date) => {
        if(date){ 
            setFinalDate(date);
        }
    }

    const handleProductors = (value, action) => {
        let productorAux = [...productor];
    
        if (action === "add") {
            if (Array.isArray(value)) {
                value.forEach(val => {
                    const selectedProductor = productors[val];
                    if (!productorAux.some(p => p.id === selectedProductor.id)) {
                        productorAux.push(selectedProductor);
                    }
                });
            } else {
                const selectedProductor = productors[value];
                if (!productorAux.some(p => p.id === selectedProductor.id)) {
                    productorAux.push(selectedProductor);
                }
            }
        } else {
            productorAux.splice(value, 1);
        }
    
        setProductor(productorAux);
    };

    function organizeRows(data){
        let organizedRows = {
            animal:[],
            batch:[],
        };

        const modifyCuttingMeatColor = (color) => {
            return color === 'Laranja' ? 'Amarelo' : color;
        };

        data.animal.sort((a, b) => {
            if (a.batch_id === b.batch_id) {
                return a.carcass_id - b.carcass_id;
            }
            return a.batch_id - b.batch_id;
        });

        data.animal.forEach((animal) => {
            const a = { ...animal };
            let animalRow = [];
            Constants.animal.forEach((column) => {
                animalRow.push(modifyCuttingMeatColor(a[column.key]));
            });
            organizedRows.animal.push(animalRow);
        });

        data.batch.sort((a, b) => {
            return a.batch_id - b.batch_id;
        });
    
        data.batch.forEach((batch) => {
            const b = { ...batch };
            let batchRow = [];
            Constants.batch.forEach((column) => {
                batchRow.push(b[column.key]);
            });
            organizedRows.batch.push(batchRow);
        });
    
        return organizedRows;
    }

    const handleErrors = (err) => {
        setErrors(prevErrors => {
            if (err && Object.keys(err).length > 0) {
                const newErrors = { ...prevErrors };
                Object.keys(err).forEach(key => {
                    if (!err[key]) {
                        delete newErrors[key];
                    } else {
                        newErrors[key] = err[key];
                    }
                });
                return newErrors;
            } else {
                return {};
            }
        });
    };

    const validateFields = () => {
        let errors = {};
    
        if (!initialDate) {
            errors.initialDate = true;
        } else {
            errors.initialDate = false;
        }
    
        if (!finalDate) {
            errors.finalDate = true;
        } else {
            errors.finalDate = false;
        }
    
        if (productor.length === 0) {
            errors.productor = true;
        } else {
            errors.productor = false;
        }
    
        handleErrors(errors);

        if (Object.keys(errors).length === 0 || Object.values(errors).every(error => !error)) {
            setShouldDownload(true);
        }
    };

    const downloadBatchExcel = async () => {
        setShouldDownload(false);

        validateFields();
    };

    useEffect(() => {
        if (shouldDownload) {
           
            (async () => {
                const sendData = {
                    initialDate: `${format(initialDate, 'yyyy-MM-dd')} 00:00:00`,
                    finalDate: `${format(finalDate, 'yyyy-MM-dd')} 23:59:59`,
                    dateAbout: parseInt(period),
                    selectedProductors: productor
                };

                const dataRows = await API.post('reportProductorSheetFile', sendData);
                if (dataRows.status === 200) {
                    let data = organizeRows(dataRows.data);
                    let sheetData = [
                        {
                            columns: Constants.animal.map((item) => item.name),
                            data: data.animal,
                            sheetName: 'Animais'
                        },
                        {
                            columns: Constants.batch.map((item) => item.name),
                            data: data.batch,
                            sheetName: 'Lote'
                        }
                    ];
                    downloadCustomExcel(sheetData, `Relatorio-Sheep-${dataRows.data.batch[0].processment_plant}-${format(initialDate, 'dd-MM-yyyy')}`);
                } else {
                    alert("Erro ao baixar a planilha");
                }
            })();
        }
        setShouldDownload(false);
    }, [shouldDownload]);

    return(
        <Container className="tooltipBoundary">
            <Title className='large tooltipBoundary'>Relatório</Title>
            <FilterContainer>
                <div>
                    <InputTitle className="medium">Data Inicial</InputTitle>
                    <StyledDatePicker 
                    dateFormat="dd/MM/yyyy"
                    selected={initialDate}
                    onChange={handleFormatInitialDate} 
                    placeholderText="Selecionar data"
                    locale={pt}
                    error={errors.initialDate}
                    />
                </div>
                <div>
                    <InputTitle className="medium">Data Final</InputTitle>
                    <StyledDatePicker 
                    dateFormat="dd/MM/yyyy"
                    selected={finalDate}
                    onChange={handleFormatFinalDate} 
                    placeholderText="Selecionar data"
                    locale={pt}
                    error={errors.finalDate}
                    />
                </div>
                <div>
                    <InputTitle className="medium">Período referente a</InputTitle>
                    <SelectContainer>
                        <Select value={period} onChange={(e)=>{
                            setPeriod(e.target.value)
                            }} error={errors.period}>

                        {periods?.map((s, index) => (
                            <option key={index} value={index}>
                                {s}
                            </option>
                        ))}
                        </Select>
                        <FakeBorder error={errors.period}/>
                    </SelectContainer>
                </div>
            </FilterContainer>
            <FilterProductorsContainer>
                <div>
                    <InputTitle className="medium">Produtores</InputTitle>
                    <ProductorSelector>
                        <ProductorsContainer>
                            {productors.length !== 0 ?
                                productor.map((r,index)=> (
                                    <SelectedProductors>
                                        <div style={{ marginRight:3, fontSize:12, cursor:'pointer' }} onClick={()=>{handleProductors(index, 'remove');}}>
                                            x
                                        </div>
                                        <div>{r.name}</div>
                                    </SelectedProductors>
                                ))  : 
                                    initialDate && finalDate ?
                                        'Não há produtores para este período'
                                            : '' 
                            }
                             
                        </ProductorsContainer>
                        <FakeContainer> 
                        <Select 
                            value={-1} 
                            style={{ width: '11ch', borderRadius: '0px' }} 
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '-2') {
                                    // Adicionar todos os produtores
                                    const allIndexes = productors.map((_, index) => index);
                                    handleProductors(allIndexes, 'add');
                                } else {
                                    handleProductors(parseInt(value, 10), 'add');
                                }
                            }} 
                            error={errors.productor}
                        >
                                <option value={-1} style={{marginRight:'3px'}}>Produtores</option>
                                <option value={-2} style={{ marginRight: '3px' }}>Todos</option>
                                {productors?.map((r, index) => (
                                    <option key={index} value={index}>
                                        {r.name}
                                    </option>
                                ))}
                            </Select>
                            <FakeBorder error={errors.productor}/>
                        </FakeContainer>    
                    </ProductorSelector>     
                </div>
            </FilterProductorsContainer>
            <ButtonContainer>
                <div/>
                <div/>
                <div/>
                <div/>
                <GenerateButton className="medium" onClick={()=>{downloadBatchExcel()}}>{'Gerar Planilha'}</GenerateButton>
            </ButtonContainer>
            
        </Container>
    )

}