import React, { useEffect, useState } from 'react';
import { ButtonValue, Container, GridButtom } from './styles';
import MobileLayout from './Mobile';
import LandscapeLayout from './Landscape';
import DesktopLayout from './Desktop';

import Cookie from 'js-cookie';
import API from '../../services/API/index';
import { useParams } from 'react-router';
import { useWarn } from '../../context/Modal';

// REDUX
import {useSelector} from 'react-redux';


export default function Evaluation(){

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );

    const [animalList, setAnimalList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [batchId, setBatchId] = useState();
    const [header, setHeader] = useState({ batch:'', date:'', productor:''})
    const [grValues, setGrValues] = useState({});
    const [phValues, setPhValues] = useState({});
    const [estadoBotoes, setEstadoBotoes] = useState({});
    const [selectedAnimalId, setSelectedAnimalId] = useState(null);
    
    const technicianId = Cookie.get('sheep-technician');
    const { batch } = useSelector(state => state.Reducers);
    let {id} = useParams();
    const {warn, closeWarn} = useWarn();


    const saveToLocalStorage = (data) => {
        localStorage.setItem('batchState', JSON.stringify(data));
    };
 
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1000);
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      useEffect(() => {
        const urlParams =  new URLSearchParams(window.location.search);
        setBatchId(parseInt(urlParams.get("id")));
        setHeader({
            batch: urlParams.get("id"),
            date : urlParams.get("date"),
            productor: urlParams.get("productor"),
            industryBatch: urlParams.get("industry")
        })
    }, []);

    useEffect(() => {
        if (!loading) {
        const novoEstadoBotoes = {
            grSelected:'',
            phSelected:'',
            marblingSelected:'',
            temperatureSelected:''
        };

        animalList.forEach(data => {
        novoEstadoBotoes[data.animal.id] = {
            phSelected: data.evaluations.pH ? data.evaluations.pH : '',
            grSelected: data.evaluations.fat_thickness !== null && data.evaluations.fat_thickness !== ''? parseInt(data.evaluations.fat_thickness) : '',
            marblingSelected: data.evaluations.meat_marbling ? data.evaluations.meat_marbling : '',
            temperatureSelected: data.evaluations.temperature  !== null && data.evaluations.temperature !== ''? data.evaluations.temperature : ''
        };
        
        if(novoEstadoBotoes[data.animal.id].grSelected !== null){
            setGrValues(prevGrValues => ({
                ...prevGrValues,
                [data.animal.id]: novoEstadoBotoes[data.animal.id].grSelected
            })); 
        }

        if(novoEstadoBotoes[data.animal.id].phSelected !== null){
            setPhValues(prevpHValues => ({
                ...prevpHValues,
                [data.animal.id]: novoEstadoBotoes[data.animal.id].phSelected
            })); 
        }

        });
        setEstadoBotoes(novoEstadoBotoes);
        }
      }, [animalList, loading]);

    const temperatureTransformation = (t) => {
        if((t !== null && t !== undefined && t !== '') && t !== -1){

                if(t === 0)
                return '0 ~ 3'
                if(t === 1)
                return '3,1 ~ 6'
                if(t === 2)
                return '6,1 ~ 9'
                if(t === 3)
                return '9,1 ~ 12'
                if(t === 4)
                return '> 12'            
            }
            else return '-'
    }

      async function AnimalsList() {
        if(batchId){
            await API.get(`animalsFromBatch/${batchId}`) 
            .then((res) => {

                const createdAnimals = res.data.filter(animal => animal.evaluations.status === 'CREATED' || Object.keys(animal.evaluations).length === 0).sort((a, b) => a.animal.carcass_id - b.animal.carcass_id);

                const otherAnimals =  res.data.filter(animal => (Object.keys(animal.evaluations).length !== 0 && animal.evaluations.status !== 'CREATED')).sort((a, b) => a.animal.carcass_id - b.animal.carcass_id);
    
                const sortedAnimalList = [...createdAnimals, ...otherAnimals];
                const animalList = sortedAnimalList.filter(((animals) => animals.animal.status !== 'DELETED'))
                    
                setAnimalList(animalList);
                setLoading(false);
            })
            .catch((error) => {
                console.error('err',error);
            });
        } else{
            await API.get(`animalsFromBatch/${batch.id}`) 
                .then((res) => {
                   
    
                    const createdAnimals = res.data.filter(animal => animal.evaluations.status === 'CREATED' || Object.keys(animal.evaluations).length === 0).sort((a,b)=> a.animal.carcass_id - b.animal.carcass_id)

                    const otherAnimals =  res.data.filter(animal => (Object.keys(animal.evaluations).length !== 0 && animal.evaluations.status !== 'CREATED')).sort((a, b) => a.animal.carcass_id - b.animal.carcass_id);
        
                    const sortedAnimalList = [...createdAnimals, ...otherAnimals];
                    const animalList = sortedAnimalList.filter(((animals) => animals.animal.status !== 'DELETED'))
                    
                    setAnimalList(animalList);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('error',error);
                });
        }
    }

    const sendUpdate = async (id, name, value) =>{
        let obj = {
            animal_id:id,
            technician_id:technicianId
        }
        if (name === 'pH' && typeof value === 'string') {
            obj[name] = parseFloat(value.replace(',', '.'));
        } else {
            obj[name] = value;
        }

        await API.post('updateAnimal', obj)
        .then(()=>{
            let  updatedList = [...animalList].map((a)=>({...a}));
            let index = null;

            updatedList.forEach((item,i)=>{
                if(item.animal.id === obj.animal_id){
                    index = i;
                }   
            })
            if(!updatedList[index].evaluations.status){
                updatedList[index].evaluations.status = "CREATED";
                updatedList[index].evaluations.color = 'yellow';
                updatedList[index].evaluations[name] = value
                setAnimalList(updatedList);
            }
        })
        
    }

    useEffect(() => {
        AnimalsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, batchId]);

    const handleSquareClick = (e) => {
        e.stopPropagation();
    };

    const handlePhButtonClick = (animalId, value) => {
        setEstadoBotoes(prevState => ({
          ...prevState,
          [animalId]: {
            ...prevState[animalId],
            phSelected:value,
          },
        }));
    };

    const handleGrButtonClick = (animalId, value) => {
        setEstadoBotoes(prevState => ({
            ...prevState,
            [animalId]: {
                ...prevState[animalId],
                grSelected: value,
            },
        }));
    };

    const handleMarblingButtonClick = (animalId, value) => {
        setEstadoBotoes(prevState => ({
            ...prevState,
            [animalId]: {
            ...prevState[animalId],
            marblingSelected: value,
            },
        }));
    };


    const handleTemperatureButtonClick = (animalId, value) => {
        setEstadoBotoes((prevState) => {
            // Verifica se o valor já está definido corretamente para evitar atualizações desnecessárias
            if (prevState[animalId]?.temperatureSelected === value) return prevState;
            return {
                ...prevState,
                [animalId]: {
                    ...prevState[animalId],
                    temperatureSelected: value,
                },
            };
        });
    };

    const handlePhMinusClick = (animalId) => {
        const currentPhValue = (phValues[animalId] !== undefined && phValues[animalId] !== null && typeof phValues[animalId] === 'number' && !isNaN(phValues[animalId])) ? phValues[animalId] : 5.5;
        let newPhValue = currentPhValue - 0.4;
        if (newPhValue < 5.2) {
            newPhValue = 5.2;
        }
        setPhValues(prevPhValues => ({
            ...prevPhValues,
            [animalId]: newPhValue
        }));
    };
    

    const handlePhPlusClick = (animalId) => {
        const currentPhValue = (phValues[animalId] !== undefined && phValues[animalId] !== null && typeof phValues[animalId] === 'number' && !isNaN(phValues[animalId])) ? phValues[animalId] : 5.5;
        let newPhValue = currentPhValue + 0.4;
        if (newPhValue >= 7) {
            newPhValue = 6.7;
        }
        // Arredonda o novo valor para uma casa decimal
        newPhValue = Math.round(newPhValue * 10) / 10;
        setPhValues(prevPhValues => ({
            ...prevPhValues,
            [animalId]: newPhValue
        }));
    };

    const softDeleteAnimal = async (animalId, carcassId) =>{
        warn({
        title:'Atenção!',
        body: <p style={{fontSize:'15px'}}>Você deseja mesmo deletar o animal {carcassId} ?</p>,
        showCancelButton:true,
        size:'180',
        secondButton:{label:'Excluir',
        color:'#d25650',function: async ()=>{
            await API.post('softDeleteAnimal/'+animalId, {batchId:batchId}).then((res)=>{
                
                if(res.status === 200){
                    
                    setAnimalList(animalList.filter((animals) => animals.animal.id!== animalId));
                    closeWarn()
                }
            }).catch((error)=>{console.error('error on soft delete', error)})
        }}})
    }

    const renderGrNumberButtons = (animalId) => {
        const currentGrValue = (grValues[animalId] !== undefined && grValues[animalId] !== null && typeof grValues[animalId] === 'number' && !isNaN(grValues[animalId])) ? grValues[animalId] : 6;
        const buttons = [];
        for (let i = currentGrValue ; i <= currentGrValue + 3; i++) {
            
            if(i >= 0){

                buttons.push(
                    <GridButtom
                        portrait={isPortrait}
                        key={i}
                        onClick={(e) => {
                            handleSquareClick(e);
                            if(i >= 0 && i <= 60){
                                handleGrButtonClick(animalId, i)
                                sendUpdate(animalId, 'fat_thickness', i);
                            }
                        }}
                        style={{
                            backgroundColor: estadoBotoes[animalId]?.grSelected === i ? '#275224' : i >= 0 && i <= 60 ? '#D9D9D9': '' ,
                            color: estadoBotoes[animalId]?.grSelected === i ? '#FFF' : '#000',
                            cursor: i >= 0 && i <= 60 ? 'pointer' : 'initial'
                        }}
                    >
                        <ButtonValue portrait={isPortrait} className='medium'>{i >= 0 && i <= 60 ? i : ''}</ButtonValue>
                    </GridButtom>
                );
            }
        }
       
        return buttons;
    };

    const renderPhNumberButtons = (animalId) => {
        const currentPhValue = (phValues[animalId] !== undefined && phValues[animalId] !== null && typeof phValues[animalId] === 'number' && !isNaN(phValues[animalId])) ? phValues[animalId] : 5.5;
        const buttons = [];
        const minValue = 5.2;
        const maxValue = 7;
        const increment = 0.1;


        for (let i = currentPhValue; i <= currentPhValue + 3 * increment; i += increment) {
            const isWithinRange = (i >= minValue && i <= maxValue);
            if(i<=7){
                buttons.push(
                    <GridButtom
                        portrait={isPortrait}
                        key={i}
                        onClick={(e) => {
                            handleSquareClick(e);
                            if (isWithinRange) {
                                handlePhButtonClick(animalId, (Math.ceil(i * 10) / 10));
                                sendUpdate(animalId, 'pH', (Math.ceil(i * 10) / 10));
                            }
                        }}
                        style={{
                            backgroundColor: parseFloat(estadoBotoes[animalId]?.phSelected) === (Math.ceil(i * 10) / 10) ? '#275224' : (isWithinRange ? '#D9D9D9' : '#F0F0F0'),
                            color: parseFloat(estadoBotoes[animalId]?.phSelected) === (Math.ceil(i * 10) / 10) ? '#FFF' : '#000',
                            cursor: isWithinRange ? 'pointer' : 'initial',
                        }}
                    >
                        <ButtonValue portrait={isPortrait} className='medium'>{isWithinRange ? i.toFixed(1).replace('.',',') : ''}</ButtonValue>
                    </GridButtom>
                );
            }
        }
        return buttons;
    };

    const handleMinusClick = (animalId) => {
        const currentGrValue = (grValues[animalId] !== undefined && grValues[animalId] !== null && typeof grValues[animalId] === 'number' && !isNaN(grValues[animalId])) ? grValues[animalId] : 6;
        let newGrValue = currentGrValue - 4;
        if (newGrValue < 0) {
            newGrValue = 0;
        }
        setGrValues(prevGrValues => ({
            ...prevGrValues,
            [animalId]: newGrValue
        }));
    };

    const handlePlusClick = (animalId) => {
        const currentGrValue = (grValues[animalId] !== undefined && grValues[animalId] !== null && typeof grValues[animalId] === 'number' && !isNaN(grValues[animalId])) ? grValues[animalId] : 6;
        let newGrValue = Math.min(currentGrValue + 4, 60);
        if(newGrValue >=60){
            newGrValue = 57;
        }
        setGrValues(prevGrValues => ({
            ...prevGrValues,
            [animalId]: newGrValue
        }));
    };


        const updateAnimal = async (animal) => {

            let update = {"animals":
            [
                {
                    "animal":{
                        "id":animal.animal.id,
                        "status": animal.animal.status,
                        "carcass_id": animal.animal.carcass_id,
                        "sex": animal.animal.sex,
                        "dentition": animal.animal.dentition,
                        "carcass_weight": animal.animal.carcass_weight,
                        "initial_pH": animal.animal.initial_pH,
                        "initial_temp": animal.animal.initial_temp,
                        "earring": animal.animal.earring,
                        "sheep": animal.animal.sheep,
                        "creation_date": animal.animal.creation_date,
                        "batch_id": animal.animal.batch_id,
                        "supplier_id": animal.animal.supplier_id,
                        "alive_weight": animal.animal.alive_weight
                    },
                    "evaluation":{
                        "animal_id": animal.animal.id,
                        "fat_thickness":estadoBotoes[animal.animal.id].grSelected !== '' ? estadoBotoes[animal.animal.id].grSelected : -1,
                        "pH":estadoBotoes[animal.animal.id].phSelected ? estadoBotoes[animal.animal.id].phSelected : -1,
                        "temperature": estadoBotoes[animal.animal.id].temperatureSelected !== undefined && estadoBotoes[animal.animal.id].temperatureSelected !== ''? estadoBotoes[animal.animal.id].temperatureSelected : -1,
                        "meat_marbling": estadoBotoes[animal.animal.id].marblingSelected ? estadoBotoes[animal.animal.id].marblingSelected : -1,
                        "technician_id": 1
                    }
    
                }
            ]}
            setSelectedAnimalId('');
    
            await API.post('updateAllAnimals', update).then(
                (response) => {
                    AnimalsList();
                }
              ).catch((error) => {
                  console.error(error.message);
                })
        }

    return(

        <Container>
          
            {(isMobile && isPortrait) ? (
                <MobileLayout AnimalsList={AnimalsList} sendUpdate={sendUpdate} animalList={animalList} softDeleteAnimal={softDeleteAnimal} 
                loading={loading} renderGrNumberButtons={renderGrNumberButtons} handleMinusClick={handleMinusClick} batchId={batchId} updateAnimal={updateAnimal}
                handlePlusClick={handlePlusClick} header={header} estadoBotoes={estadoBotoes}
                handleMarblingButtonClick={handleMarblingButtonClick} handleTemperatureButtonClick={handleTemperatureButtonClick} handlePhMinusClick={handlePhMinusClick}
                handlePhPlusClick={handlePhPlusClick} renderPhNumberButtons={renderPhNumberButtons} temperatureTransformation={temperatureTransformation} 
                selectedAnimalId={selectedAnimalId} setSelectedAnimalId={setSelectedAnimalId} saveToLocalStorage={saveToLocalStorage}/>
            ) 

            : 
                <>
                    {(isMobile && !isPortrait) ? (
                        <LandscapeLayout AnimalsList={AnimalsList} sendUpdate={sendUpdate} animalList={animalList} softDeleteAnimal={softDeleteAnimal}
                        loading={loading} renderGrNumberButtons={renderGrNumberButtons} handleMinusClick={handleMinusClick} batchId={batchId} updateAnimal={updateAnimal}
                        handlePlusClick={handlePlusClick} header={header} estadoBotoes={estadoBotoes}
                        handleMarblingButtonClick={handleMarblingButtonClick} handleTemperatureButtonClick={handleTemperatureButtonClick} handlePhMinusClick={handlePhMinusClick}
                        handlePhPlusClick={handlePhPlusClick} renderPhNumberButtons={renderPhNumberButtons} temperatureTransformation={temperatureTransformation} 
                        selectedAnimalId={selectedAnimalId} setSelectedAnimalId={setSelectedAnimalId} saveToLocalStorage={saveToLocalStorage}/>
                    )

                    :  
                        <DesktopLayout AnimalsList={AnimalsList} sendUpdate={sendUpdate} animalList={animalList} softDeleteAnimal={softDeleteAnimal}
                        loading={loading} renderGrNumberButtons={renderGrNumberButtons} handleMinusClick={handleMinusClick} batchId={batchId} updateAnimal={updateAnimal}
                        handlePlusClick={handlePlusClick} header={header} estadoBotoes={estadoBotoes}
                        handleMarblingButtonClick={handleMarblingButtonClick} handleTemperatureButtonClick={handleTemperatureButtonClick} handlePhMinusClick={handlePhMinusClick}
                        handlePhPlusClick={handlePhPlusClick} renderPhNumberButtons={renderPhNumberButtons} temperatureTransformation={temperatureTransformation}
                        selectedAnimalId={selectedAnimalId} setSelectedAnimalId={setSelectedAnimalId} saveToLocalStorage={saveToLocalStorage}/>
                    }
                </>
            }
        </Container>
    )

}