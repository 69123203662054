export const cookies = [
    {
        name: 'sheep-fridge-name',
        objective: 'Mostrar o nome do frigorífico em certas páginas',
        duration: '15 dias',
        type: 'Cookies',
    },
    {
        name: 'sheep-user-name',
        objective: 'Mostrar o nome do usuário em certas páginas',
        duration: '15 dias',
        type: 'Cookies',
    },
    {
        name: 'sheep-fridge-data',
        objective: 'Usar o id do frigorífico para realizar uma comparação em uma lógica',
        duration: '15 dias',
        type: 'Cookies',
    },
    {
        name: 'sheep-user-id',
        objective: 'Realizar pesquisas no banco de dados através do id do usuário',
        duration: '15 dias',
        type: 'Cookies',
    },
    {
        name: 'sheep-user-token',
        objective: 'Chave de acesso para autenticar o usuário a navegar nas páginas do aplicativo',
        duration: '15 dias',
        type: 'Cookies',
    },
]

export const localKeys = [
    {
        name: 'rootCadastro',
        objective: 'Armazena valores para o funcionamento do aplicativo',
        duration: 'Indeterminado',
        type: 'LocalStorage',
    },
]