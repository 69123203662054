import styled from "styled-components";

export const Button = styled.button`
    height:45px;
    border:none;
    border-radius:5px;
    font-size:20px;
    margin-top:20px;
    background-color:${props =>props.backgroundColor};
    margin:${props =>props.margin ? props.margin : '0'};
    width:17%;
    cursor:pointer;
    color:#FFF;
`

