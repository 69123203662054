import styled, { css } from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
    width:100%;
    height:100%;
`

export const Title = styled.div`
    font-size:32px;
    color:#275224;
`

export const InputsContainer = styled.div`
    width:100%;
    display:flex;
    // align-items:center;
    justify-content:space-between;
    margin-top:30px;
    font-size:16px;
`

export const SmallerInput = styled.div`
    width:40%;
    display:flex;
    flex-direction:column;
`

export const BiggerInput1 = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
`

export const InputTitle = styled.div`
    color:#275224;
    width:100%;
`

export const Input = styled.input`
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    ${p=> p.error?css`
    border: solid;
    border-width:2px;
    border-color:#EE4245;
    `:''}
    font-size:16px;
    margin-top:5px;
    padding-left:10px;
    color:#575757;
`

export const Select = styled.select`

    -webkit-appearance: none;

    color: #575757;

    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px 0 0 5px;
    ${p=> p.error?css`
    border: solid;
    border-width:2px 0px 2px 2px;
    border-color:#EE4245;
    `:''}
    font-size:16px;
    margin-top:5px;
    padding-left:10px;  
    width:98%;
    outline:none;
`

export const InputDivisionLeft = styled.div`
    display:flex;
    flex-direction:column;
    width:35%;
`

export const InputDivisionMiddle = styled.div`
    display:flex;
    flex-direction:column;
    width:35%;
`

export const InputDivisionRight = styled.div`
    display:flex;
    flex-direction:column;
    width:12%;
`

export const SmallersContainer= styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:30px;
`

export const BiggerContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:30px;
`

export const NextButton = styled.button`
    height:45px;
    border:none;
    border-radius:5px;
    font-size:20px;
    margin-top:20px;
    background-color:#275224;
    width:17%;
    cursor:pointer;
    color:#FFF;

    @media (max-width:1400px) {
        font-size:18px
    }
`

export const ButtonsContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    ${p=> p.error?css`
    border: solid;
    border-width:2px;
    border-color:#EE4245;
    border-radius:5px;
    padding:5px;
    `:''}
`

export const AquilesButton = styled.button`
    height:45px;
    background-color:#DB7D57;
    cursor:pointer;
    margin-top:5px;
    width:40%;
    border:${(props) => props.selected === 'Aquiles' ? '3px solid #275224' : 'none'};
    border-radius:5px;
    font-size:16px;
    color:${(props) => props.selected === 'Aquiles' ? '#275224' : '#FFF'};

    transition: font-size 0.3s ease;
    &:hover{
        font-size:17px;
    }
`

export const PelvesButton = styled.button`
    height:45px;
    background-color:#DB7D57;
    cursor:pointer;
    margin-top:5px;
    width:40%;
    border:${(props) => props.selected === 'Pelves' ? '3px solid #275224' : 'none'};
    border-radius:5px;
    font-size:16px;
    color:${(props) => props.selected === 'Pelves' ? '#275224' : '#FFF'};

    transition: font-size 0.3s ease;
    &:hover{
        font-size:17px;
    }
`

export const FakeContainer = styled.div`
    display:flex;
    align-items:center;
`

export const FakeBorder = styled.div`
width:10px;
background-color:#D9D9D9;
height:45px;
margin-top:5px;
border-radius:0 5px 5px 0;
${p=> p.error?css`
    border: solid;
    border-width:2px 2px 2px 0px;
    border-color:#EE4245;
    height:41px;
    `:''}
`

export const ContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:400px;
`

export const NextContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
`

export const StyledDatePicker = styled(DatePicker)`
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    font-size:16px;
    margin-top:5px;
    padding-left:10px;
    width:100%;
    color:#575757;
    ${p=> p.error?css`
    border: solid;
    border-width:2px;
    border-color:#EE4245;
    `:''}

    @media (max-width:1400px){
        font-size:16px;
    }
`

export const IndustryInput = styled.input`
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    ${p=> p.error?css`
    border: solid;
    border-width:2px;
    border-color:#EE4245;
    `:''}
    font-size:16px;
    margin-top:5px;
    padding-left:10px;
    color:#575757;
    width:100%;
`

