import React, { useState, useEffect } from 'react';
import { Container, Title, NoBatchesDiv } from './styles';

import Cookie from 'js-cookie';
import Helpers from '../../services/helpers/index';
import API from '../../services/API/index';

import MobileBatches from './Mobile';
import LandscapeBatches from './Landscape';
import DesktopBatches from './Desktop';
import Constants from './constants';
import downloadCustomExcel from '../../components/DownloadExcel/index';
import { setBatchToUpdate, setBatchToEvaluation } from '../../store/modules/batch/actions';

// REDUX
import {useDispatch} from 'react-redux'
import { useNavigate } from 'react-router';
import { useWarn } from '../../context/Modal/index';
import Summary from '../../components/Batch/Summary';
import { S3 } from '../../services/AWS';
import download from 'downloadjs';

export default function Batches(){
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );
    const [batches,setBatches] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { warn } = useWarn();


    async function batchesData(){
        let batchesInfo = []
        API.get('batchesByCompany/' + Cookie.get('sheep-company-id')).then((res)=>{
            if(res.data.length > 0){
                res.data.map((item)=>
                    batchesInfo.push({
                        id : item.id,
                        processment_plants_name: item.processmentPlant,
                        slaughter_plants_name: item.slaughterPlant,
                        processment_plants: item.procesmenPlantNameAndId,
                        slaughter_plants: item.slaughterPlantNameAndId,
                        animals_quantity: item.animalsQtt,
                        slaughter_date: Helpers.formatDate(item.slaughterDate),
                        creation_date:Helpers.formatDate(item.createdAt),
                        status: item.status,
                        evaluation_status:item.classification === "APROVADO"? "Aprovado" : item.classification === "REPROVADO" ? "Reprovado" :item.classification?"Aprovado com ressalvas":"Rascunho" ,
                        isDorper: item.isDorper,
                        supplier: item.supplier,
                        farm: item.farm,
                        productor:item.productor,
                        compliance:item.compliances,
                        races: item.races,
                        deaths: item.deaths,
                        observation: item.observation,
                        hang_method: item.hangMethod,
                        avg_live_weight: item.liveWeight,
                        max_age_declared: item.maxAge,
                        compliance_id: item.compliances.id,
                        shearing_status: item.shearing,
                        ph_average: parseFloat(item.phinitial).toFixed(1),
                        media: item.media??null,
                        media_id: item.media?item.media.id:null,
                        industry_batch:item.industryBatch??null
                    })
                )

                batchesInfo.sort((a,b)=> b.id - a.id)
                setBatches(batchesInfo)
            }
        })
    }

    useEffect(()=>{
        batchesData()
    },[])

    const clearLocalStorage = () => {
        localStorage.removeItem('batchState');
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            clearLocalStorage();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1000);
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      async function softDeleteBatch(batch){
        if(batch.status === 'CREATED'){
            await  API.get(`softBatchDelete/${batch.id}`).then( _ => {
                setBatches(batches => batches.filter(b => b.id !== batch.id))
              }).catch((error)=>{
                console.error(error);
              })
        }else{
            alert("Não é possivel deletar o lote pois ele já foi finalizado ou está em progresso")
        }
    }

    function organizeRows(data){
        let organizedRows = {
            animal:[],
            batch:[],
        };

        const modifyCuttingMeatColor = (color) => {
            return color === 'Laranja' ? 'Amarelo' : color;
        };

        data.animal.sort((a, b) => a.carcass_id - b.carcass_id);

        data.animal.forEach((animal) => {
            const a = { ...animal };
            let animalRow = [];

            a.industry_batch = data.batch[0]?.industry_batch;
            
            Constants.animal.forEach((column) => {
                animalRow.push(modifyCuttingMeatColor(a[column.key]));
            });
            organizedRows.animal.push(animalRow);
        });
    
        data.batch.forEach((batch) => {
            const b = { ...batch };
            let batchRow = [];
            Constants.batch.forEach((column) => {
                batchRow.push(b[column.key]);
            });
            organizedRows.batch.push(batchRow);
        });
    
        return organizedRows;
    }

    async function downloadBatchExcel(batchId){
        const dataRows = await API.post('dataSheetFile', { batchId });
        if(dataRows.status === 200){
            let data = organizeRows(dataRows.data);
            let sheetData = [
            {
                columns: Constants.animal.map((item)=> item.name),
                data:data.animal,
                sheetName:'Animais'
            },
            {
                columns:Constants.batch.map((item)=> item.name),
                data:data.batch,
                sheetName:'Lote'
            }
            ]

            downloadCustomExcel(sheetData, `Lote-${batchId}`);
        }else{
            alert("Erro ao baixar a planilha");
        }
    }

    function handleNavigateToBatch (batch) {
        
        if(batch.status === 'CREATED' && batch.evaluation_status !== 'Reprovado' ){
            batch.slaughter_date = Helpers.formatDate(batch.slaughter_date,'us');
            dispatch(setBatchToUpdate(batch))
            navigate('/register');
        }else{
            
            if(batch.evaluation_status === 'Reprovado'){
                alert('O lote foi reprovado!')
            }else{
                warn({ 
                    title:'Atenção!',
                    body: <p style={{fontSize:'15px'}}>Não é possível editar um lote finalizado ou em progresso!</p>,
                    showCancelButton:false,
                    secondButton:{label:'Fechar',
                    color:'#275224'}
                })
            }
        }
    };

    const handleNavigateToEvaluation = async (batch) => {
        const formattedDate = encodeURIComponent(batch.slaughter_date);            
        dispatch(setBatchToEvaluation({...batch, status: "SENT"}));
        localStorage.setItem('batchState', JSON.stringify(batch.id));

        if(batch.status === 'CREATED'){
            let batchAux = {status: 'SENT',compliance: {}}
            await API.put('/batches/' + batch.id, {batch: batchAux, races: []}).then(()=>{
                batchesData()
                batch.batchWillReciveUpdate = false
                navigate(`/Evaluation/?id=${batch.id}&date=${formattedDate}&productor=${batch.productor.user.name}&industry=${batch.industry_batch}`);
            })
        }else{
            batch.batchWillReciveUpdate = false
            navigate(`/Evaluation/?id=${batch.id}&date=${formattedDate}&productor=${batch.productor.user.name}&industry=${batch.industry_batch}`);
        }
              
    };

    const handleSummary = async (batch)  => {
        try {
            let animalInfo = (await API.get(`/getAnimalsFromBatch/${batch.id}`)).data

            animalInfo.productor = batch.productor
            animalInfo.farm = batch.farm
            animalInfo.cadUser = batch.supplier
            warn({ title: "Dados sobre o lote", body:<Summary data={animalInfo}/>, size:1000, showCancelButton:false, secondButton:{label:'Fechar', color:'#275224'},marginBottom: '20px', marginTop: window.innerHeight <= 618 ? '450px' : '220px' })
        } catch (error) {
            console.error('handle summary error', error)
        }
    }

    async function downloadMedia(mediaArray) {
        for (const media of mediaArray) {
            try {
                const name = media.sheep_media_url.split("amazonaws.com/")[1];
                const params = {
                    Bucket: 'sheep-medias',
                    Key: name,
                };
        
                const data = S3.getSignedUrl('getObject', params);
                const response = await fetch(data);
                const blob = await response.blob();
        
                download(blob, name, media.type);
                
            } catch (error) {
                console.error('Error downloading media:', error.message);
            }
        }
    }
 
    return(
        <Container className="tooltipBoundary">
            <Title className='large tooltipBoundary'>Lotes</Title>

            {batches.length > 0 ? 
                <>
                    {(isMobile && isPortrait) ? (
                        <MobileBatches softDeleteBatch={softDeleteBatch} batches={batches} downloadBatchExcel={downloadBatchExcel}
                        handleNavigateToEvaluation={handleNavigateToEvaluation} handleSummary={handleSummary}
                        downloadMedia={downloadMedia} batchesData={batchesData}/>
                    ) 
               
                    : <>
                        {(isMobile && !isPortrait) ? (
                            <LandscapeBatches softDeleteBatch={softDeleteBatch} batches={batches} downloadBatchExcel={downloadBatchExcel}
                            handleNavigateToEvaluation={handleNavigateToEvaluation} handleSummary={handleSummary}
                            downloadMedia={downloadMedia} batchesData={batchesData}/>
                        )
                        
                        : <>
                            <DesktopBatches softDeleteBatch={softDeleteBatch} batches={batches} downloadBatchExcel={downloadBatchExcel}
                                handleNavigateToBatch={handleNavigateToBatch} handleNavigateToEvaluation={handleNavigateToEvaluation} handleSummary={handleSummary}
                                downloadMedia={downloadMedia} batchesData={batchesData}/>
                          </>
                        } 
                        </>
                    }
                </>
            :
            <NoBatchesDiv>
                Sem lotes cadastrados
            </NoBatchesDiv>
              
            }
                 
        </Container>
    )

}