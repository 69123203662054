import styled, {css, keyframes } from "styled-components";
import { IoArrowForwardCircle, IoArrowBackCircle} from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(255, 215, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(255, 215, 0, 0);
  }
`;

export const PulsatingBorder = css`
  animation: ${pulse} 2s infinite;
`;

export const Container = styled.div`
    width:100% - 30px;
    height:100% - 50px;
    display:flex;
    flex-direction:column;
    justify:content:center;
    padding: 80px 70px 0px 70px;

    @media (max-width:1000px){
        width:100%;
        padding:20px;
        align-items:center;
    }
`

export const TitleContainer = styled.div`
    display:flex;
    align-items:center;
    // justify-content:center;

    @media (max-width:1000px){
        flex-direction:column;
        width:100%;
    }
`

export const TitleInfo = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:50px;
    width:100%;

    @media (max-width:1000px){
        padding:10px;
        margin-bottom:10px;
        width:90%;
        justify-content:space-between;
    }
`

export const BackButtonContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
    text-align:center;
    margin-bottom:50px;

    @media (max-width:1000px){
        margin-bottom:0;
    }
`

export const Title = styled.div`
    font-size:32px;
    color:#275224;

    @media (max-width:1000px){
        font-size:24px;
        margin-right:10px;
    }
`

export const SubtitleContainer = styled.div`
    display:flex;
    flex-direction:column;
    text-align:center;
    margin-left:80px;

     @media (max-width:1000px){
        margin-left:15px;
    }
`

export const SubtitleTitle = styled.div`
    font-size:16px;
    color:#414141;

     @media (max-width:1000px){
        font-size:14px;
    }
`

export const SubtitleText = styled.div`
    font-size:16px;
    color:#575757;

     @media (max-width:1000px){
        font-size:14px;
    }
`

export const AnimalContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    align-items:${({ isselected }) => (isselected ? 'flex-start' : 'center')};
    text-align:center;
    background-color: ${props => {
        switch (`${props.status || ''}-${props.statusColor || ''}`) {
            case '-':
                return 'rgba(96, 103, 115, 0.1)';
            case 'CREATED-yellow':
                return 'rgba(241, 198, 46, 0.17)';
            case 'FINISHED-blue':
                // return 'rgba(131, 179, 250, 0.1)';
                return 'rgba(0, 163, 16, 0.1)';
            case 'FINISHED-orange':
                // return 'rgba(241, 151, 46, 0.2)';
                return 'rgba(0, 163, 16, 0.1)';
            case 'FINISHED-green':
                return 'rgba(0, 163, 16, 0.1)';
            case 'INAPT-red':
                return 'rgba(238, 154, 154, 0.25)';
            }
        }};
    border-radius:10px;
    margin-bottom:40px;
    cursor: pointer;
    transition: height 1s ease;

    -webkit-tap-highlight-color: transparent;

    @media screen and (max-width:1000px){
        height:100%;
        width:90%;
        margin-bottom:20px;
    }
`

export const AnimalContent = styled.div`
    display:flex;
    flex-direction:column;
    width:120px;
    height:100%;
    justify-content:${({ isselected }) => (isselected ? 'flex-start' : 'space-evenly')};
    margin-top:${({ isselected }) => (isselected ? '20px' : '0')};
    transition: justify-content 0.5s ease, margin-top 0.5s ease;

    @media screen and (max-width:1600px){
        width:100px;
    }

    @media screen and (max-width:1400px){
        padding: 0 10px;
    }

    @media screen and (max-width:1000px){
        padding:5px 0;
        width:100px;
        margin:0 15px;
    }

    @media screen and (max-width:500px){
        padding:5px 0;
        width:100px;
        margin:0 5px;
    }
`

export const IdContent = styled.div`

    display:flex;
    flex-direction:column;
    width:120px;
    height:100%;
    justify-content:${({ isselected }) => (isselected ? 'flex-start' : 'space-evenly')};
    margin-top:${({ isselected }) => (isselected ? '20px' : '0')};
    transition: justify-content 0.5s ease, margin-top 0.5s ease;
    color:#000000;
    border-radius:30px;
    background-color: ${props => {
        switch (`${props.status || ''}-${props.statusColor || ''}`) {
            case '-':
                return 'rgba(96, 103, 115, 0.1)';
            case 'CREATED-yellow':
                return 'rgba(241, 198, 46, 0.17)';
            case 'FINISHED-blue':
                return 'rgba(131, 179, 250, 0.1)';
            case 'FINISHED-orange':
                return 'rgba(241, 198, 46, 0.35)';
            case 'FINISHED-green':
                return 'rgba(0, 163, 16, 0.2)';
            default:
                return 'rgba(238, 154, 154, 0.4)';
            }
        }};
    border: ${props => {
        switch (`${props.status || ''}-${props.statusColor || ''}`) {
            case '-':
                return '1px solid #606773';
            case 'CREATED-yellow':
                return '1px solid #F1C62E';
            case 'FINISHED-blue':
                return '1px solid #4D7EC6';
            case 'FINISHED-orange':
                return '1px solid #F1C62E';
            case 'FINISHED-green':
                return '1px solid #275224';
            default:
                return '1px solid #C93131';
            }
        }};
   

    @media screen and (max-width:1600px){
        width:100px;
    }

    @media screen and (max-width:1400px){
        padding: 0 10px;
    }

    @media screen and (max-width:1000px){
        padding:5px 0;
        width:100px;
        margin:0 15px;
    }

    @media screen and (max-width:500px){
        padding:5px 0;
        width:100px;
        margin:0 5px;
    }
`

export const AnimalTitle = styled.div`
    font-size:16px;
    color:#000000;

    @media screen and (max-width:1600px){
        font-size:14px;
    }

    @media screen and (max-width:1000px){
        font-size:13px;
    }
`

export const AnimalText = styled.div`
    font-size:16px;
    color:#275224;

    @media screen and (max-width:1600px){
        font-size:14px;
    }

    @media screen and (max-width:1000px){
        font-size:13px;
    }

`

export const StatusContainer = styled.div`
    display:flex;
    width:220px;
    height:40px;
    align-items:center;
    margin: 0 30px;
    padding: 0 10px;
    border-radius:30px;
    background-color: ${props => {
        switch (`${props.status || ''}-${props.statusColor || ''}`) {
            case '-':
                return 'rgba(96, 103, 115, 0.1)';
            case 'CREATED-yellow':
                return 'rgba(241, 198, 46, 0.17)';
            case 'FINISHED-blue':
                return 'rgba(131, 179, 250, 0.1)';
            case 'FINISHED-orange':
                return 'rgba(241, 198, 46, 0.35)';
            case 'FINISHED-green':
                return 'rgba(0, 163, 16, 0.2)';
            default:
                return 'rgba(238, 154, 154, 0.4)';
            }
        }};
    border: ${props => {
        switch (`${props.status || ''}-${props.statusColor || ''}`) {
            case '-':
                return '1px solid #606773';
            case 'CREATED-yellow':
                return '1px solid #F1C62E';
            case 'FINISHED-blue':
                return '1px solid #4D7EC6';
            case 'FINISHED-orange':
                return '1px solid #F1C62E';
            case 'FINISHED-green':
                return '1px solid #275224';
            default:
                return '1px solid #C93131';
            }
        }};
    margin-top:${({ isselected }) => (isselected ? '20px' : '0')};
    transition: margin-top 0.5s ease;

    @media screen and (max-width:1300px){
        padding: 0 10px;
        width:250px;
    }
    @media screen and (max-width:1000px){
        width:230px;
        margin:0 10px;
    }
`

export const TextContainer = styled.div`
    flex:1;
    text-align:center;
`

export const StatusText = styled.div`
    font-size:13px;
    color:#575757;
    margin-bottom:2px;

    @media screen and (max-width:1600px){
        font-size:12px;
    }
`

export const CaveatContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    juustify-content:center;
    width:250px;
    padding:0 10px;
    margin-top:${({ isselected }) => (isselected ? '20px' : '0')};
    transition: margin-top 0.5s ease;

    @media screen and (max-width:1300px){
        width:200px;
    }
    @media screen and (max-width:1000px){
        margin:10px 0;
    }
`

export const CaveatTitle = styled.div`
    font-size:16px;
    color:#000000;

    @media screen and (max-width:1600px){
        font-size:14px;
    }
`

export const CaveatText = styled.div`
    font-size:16px;
    color:#275224;  

    @media screen and (max-width:1600px){
        font-size:14px;
    }
`

export const ClassificationContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:200px;
    padding:0 15px;
    margin-top:${({ isselected }) => (isselected ? '20px' : '0')};
    transition: margin-top 0.5s ease;

    @media (max-width:1000px){
        padding:0;
        margin-top:0;
        width:${props => props.portrait ? '200px' : '160px'}
    }
`

export const StarIcon = styled(FaStar)`
    font-size:30px;
    color:#F1C62E;

    @media screen and (max-width:1600px){
        font-size:25px;
    }

    @media screen and (max-width:1300px){
        font-size:22px;
    }

`

export const DropdownContainer = styled.div`
    display:flex;
    width:100%;
    flex-direction:${({ isselected }) => (isselected ? 'column' : 'row')};
    align-items:center;
`

export const DropdownHeader = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    padding:30px 0;
    border-radius:10px;
    border:${props => props.dorper ? '2px solid #ffd700' : ''}; 
    ${props => props.dorper ? PulsatingBorder : ''};

     @media screen and (max-width:1000px){
        flex-direction:column;
         justify-content:center;
         padding:10px 0;
    }
`

export const StyledArrowIcon = styled(IoArrowForwardCircle)`
    font-size:60px;
    transition: transform 0.5s ease;
  ${props => props.isselected && 'transform: rotate(90deg);'}

  @media screen and (max-width:1600px){
    font-size:50px;
  }
  @media screen and (max-width:1600px){
    font-size:40px;
  }
`

export const StyledBackArrowIcon = styled(IoArrowBackCircle)`
    font-size:60px;
    cursor:pointer;

  @media screen and (max-width:1600px){
    font-size:50px;
}

  @media screen and (max-width:1000px){
    font-size:40px;
}

`

export const DropdownContentContainer = styled.div`
    margin-top:40px;
    z-index:1;
    opacity: ${({ isselected }) => (isselected ? '1' : '0')};
    height: ${({ isselected }) => (isselected ? 'auto' : '0')};
    width: ${({ isselected }) => (isselected ? 'auto' : '0')};
    overflow: hidden;
    transition: opacity 1s ease, height 1s ease;

    @media (max-width:1000px){
        margin-top:20px;
    }
`

export const DropdownContent = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:100px;
    margin-bottom:30px;

    @media screen and (max-width:1900px){
        gap:150px;
    }
    
    @media screen and (max-width:1750px){
        gap:100px;
    }
    
    @media screen and (max-width:1500px){
        gap:50px;
    }

    @media screen and (max-width:1000px){
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom:${props => props.portrait ? '30px' : '10px'};
        gap:30px;
    }
    
`

export const GridColumn = styled.div`
    display:grid;
    grid-template-rows: auto auto;
    gap:10px;
    z-index:10;

`

export const GridTitle = styled.div`
    font-size:14px;
    color:#275224;
`

export const GridButtonsContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(5, 1fr);

    @media (max-width:1000px){
        grid-template-columns: ${props => props.portrait ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)'};
        gap:${props => props.portrait ? '20px' : '5px'};
    }
`

export const GridButtonsPhGrContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(6, 1fr);
    gap:15px;

    @media (max-width:1000px){
        grid-template-columns: ${props => props.portrait ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)'};
        gap:${props => props.portrait ? '20px' : '10px'};
    }
`

export const GridButtom = styled.button`
    width:100px;
    height:100px;
    cursor:pointer; 
    border-radius:10px; 
    border:1px solid #bdbcb9; 
    transition: border-color 0.2s ease; 

    &:hover{
        border-color:#888888;
    }

    @media screen and (max-width:1800px){
        width:80px;
        height:80px;
    }

    @media screen and (max-width:1450px){
        width:70px;
        height:70px;
    }

    @media screen and (max-width:1450px){
        width:70px;
        height:70px;
    }

    @media screen and (max-width:1350px){
        width:60px;
        height:60px;
    }

    @media screen and (max-width:1000px){
        width:${props => props.portrait ? '80px' : '55px'};
        height:${props => props.portrait ? '80px' : '55px'};
    }
`


export const ButtonValue = styled.div`
    font-size:14px;

    @media screen and (max-width:1800px){
        font-size:12px;
    }

    @media screen and (max-width:1450px){
        font-size:11px;
    }

    @media screen and (max-width:1350px){
        font-size:10px;
    }

    @media screen and (max-width:1000px){
        font-size:${props => props.portrait ? '14px' : '10px'};
    }
`


export const ButtonText = styled.div`
    font-size:14px;

    @media screen and (max-width:1800px){
        font-size:12px;
    }

    @media screen and (max-width:1450px){
        font-size:11px;
    }

    @media screen and (max-width:1350px){
        font-size:9px;
    }

     @media (max-width:1000px){
        font-size:${props => props.portrait ? '12px' : '8px'};
    }
`

export const ButtonContainer = styled.div`
   display:flex;
   flex-direction:row;
   margin-bottom: 30px;
   align-items:center;
   justify-content:flex-end;
   @media screen and (max-width:1000px){
    margin:20px 0;
}
`

export const Button = styled.button`
    width:200px;
    height:40px;
    color:#FFF;
    background-color:#275224;
    border-radius:10px;
    cursor:pointer;
    border:1px solid #bdbcb9; 
    transition: border-color 0.2s ease;
    

    &:hover{
        border-color:#888888;
    }
`

export const Modal = styled.div`
    transition: opacity 0.5s ease-in-out;
    background-color:#D9D9D9;
    border-radius:20px;
    padding: 10px;
    width:120px;

    @media (max-width: 1000px){
       height:auto;
`

export const ModalContainer = styled.div`
    display: ${p=> p.visible?'flex':'none'};
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content:center;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    z-index: 1000;
`

export const DeleteModal = styled.div`
    font-size: 12px;
    width:400px;
    height:35%;
    background-color:rgba(255, 255, 255);
    margin-bottom:200px;
    border-radius:20px;
    padding:10px;

    @media (max-width:1600px){
        height:45%;
    }

    @media (max-width:1000px){
        width:60%;
        margin-bottom:0
    }
    @media (max-width:500px){
        width:80%;
        margin-bottom:0
    }
`

export const ModalHeader = styled.div`
    font-size: 18px;
    text-align: center;
    padding: 15px 5px;
    color:#000;
    margin-bottom:20px;

    @media (max-width:900px){
        margin-bottom:${props => props.portrait ? '20px' : '0'}; 
    }
`
export const ModalContent = styled.div`
    text-align: flex-start;
    color:#000;
    font-size:15px;
    padding:4px 2px;

    @media (max-width:1400px){
        padding: 1.5px; 
    }
    
`

export const ModalTextContainer = styled.div`
    display:flex;
    flex-direction:row; 

    @media (max-width:500px){
        text-align:flex-start;
        width:72%;
        padding:${props => props.portrait ? '5px' : '0'}
    }
`

export const ModalTitle = styled.div`
    text-align: flex-start;
    color:#275224;
    font-size:15px;
    padding:5px 2px;

    @media (max-width:1400px){
        padding: 1.5px 
    }
`

export const ModalActions = styled.div`
    width: 100%;
    margin: 60px 0px ;
    text-align: center;
    display:flex;
    align-items:center;
    justify-content:center;

    @media (max-width:1400px){
        margin: 40px 0px ;
    }

    @media (max-width: 900px){
        margin:${props => props.portrait ? '40px 0' : '10px 0'}; 
    }
`

export const ContentContainer = styled.div`
      width:100%;
      height:100%;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      
`

export const CancelButton = styled.button`
    width:100px;
    height:40px;
    border: 1px solid #D9D9D9;
    border-radius:3px;
    background-color:#D9D9D9;
    color:#000;
    cursor:pointer;
`

export const ExcludeButton = styled.button`
    width:100px;
    height:40px;
    border: 1px solid #275224;
    border-radius:2px;
    color:#FFF;
    cursor:pointer;
    background-color:#275224;
`

export const GridInput = styled.input`   
    width:100px;
    height:100px;
    cursor:pointer; 
    border-radius:10px; 
    border:1px solid #bdbcb9; 
    transition: border-color 0.2s ease; 
    text-align:center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size:14px;
    padding:0;

    &:hover{
        border-color:#888888;
    }

    @media screen and (max-width:1800px){
        width:80px;
        height:80px;
        font-size:12px;
    }

    @media screen and (max-width:1450px){
        width:70px;
        height:70px;
        font-size:11px;
    }

    @media screen and (max-width:1350px){
        width:60px;
        height:60px;
        font-size:10px;
    }

    @media screen and (max-width:1000px){
        width:${props => props.portrait ? '80px' : '55px'};
        height:${props => props.portrait ? '80px' : '55px'};
        font-size:${props => props.portrait ? '14px' : '10px'};
    }

`

export const InfoModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content:center;
    background: rgba(0, 0, 0, 0.7);
    overflow: auto;
    z-index: 1000;
`

export const InfoModal = styled.div`
    font-size: 12px;
    width:30%;
    height:45%;
    background-color:rgba(255, 255, 255);
    margin-bottom:200px;
    border-radius:20px;
    padding:10px;

    @media (max-width:1400px){
        width:45%;
        height:50%;
        margin-bottom:0;
    }

    @media (max-width:1400px) and (max-height:650px){
        width:45%;
        height:60%;
        margin-bottom:0;
    }

    @media (max-width:900px){
        width:${props => props.portrait ? '70%' : '70%'};
        height: ${props => props.portrait ? '75%' : '70%'};
        margin-bottom:10px;
    }

       @media (max-width:500px){
        padding:${props => props.portrait ? '0 20px' : '0'};
    }
`

export const ModalItensContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-top:30px;

    @media (max-width:1400px){
        justify-content:flex-start;
    }

    @media (max-width:900px){
        margin-top:${props => props.portrait ? '30px' : '0'};
        justify-content:${props => props.portrait ? 'flex-start' : 'center'};
    }

`

export const InfoIcon = styled(FaCircleInfo)`
    font-size:30px;

    @media (max-width:1400px){
        font-size:20px;
    }
`
