const Constants = {
    animal:
        [
            { key: "productor",                  name: "Produtor" },
            {key: "slaughter_date",              name: "Data Abate"},
            {key: "evaluation_date",             name: "Data Avaliação"},
            { key: "carcass_id",                 name: "ID Carcaça" },
            { key: "industry_batch",             name: "ID Indústria" },
            { key: "batch_id",                   name: "ID Lote" },
            { key: "evaluation_status",          name: "Status"},
            { key: "carcass_weight",             name: "PCQ (Kg)" },
            { key: "dentition",                  name: "Dentição" },
            { key: "sex",                        name: "Sexo" },
            { key: "alive_weight",               name: "Peso Vivo"},
            { key: "carcass_yield",              name: "Rendimento de Carcaça (%)"},
            { key: "sheep",                      name: "Carneiro" },
            { key: "initial_temp",               name: "Temp. Inicial" },
            { key: "initial_pH",                 name: "pH Inicial"},
            { key: "dorper",                     name: "Dorper"},
            { key: "pH",                         name: "pH Final"},
            { key: "temperature",                name: "Temp. Final"},
            { key: "fat_thickness",              name: "GR (mm)"},
            { key:"fat_classification",          name: "Classe de Acabamento"},
            { key: "meat_marbling",              name: "Marmoreio"},
            { key:"stars",                       name: "Estrelas" },
            { key:"color",                       name: "Cor de Classificação" },
            { key: "cutting_meat",               name: "Cortes de Carne (para azuis)"},
            { key: "reason",                     name: "Motivos de Desclassificação"},
            { key: "weight_class",               name: "Classe Peso"},
            { key: "yield_class",                name: "Classe RC"},
            { key: "fat_class",                  name: "Classe Gordura"},
            { key: "commodities_score",          name: "Escore Commodities"},
            { key: "commodities_classification", name: "Classificação Commodities"},
        ],
        batch:[
            { key: "batch_id",          name: "Lote ID" },
            { key: "status",            name: "Status" },
            { key: "hang_method",       name: "Método de Pendura" },
            { key: "animals_quantity",  name: "Quantidade de Animais (declarado)" },
            { key: "max_age_declared",  name: "Idade Máxima" },
            { key: "avg_live_weight",   name: "Peso Médio (Kg)" },
            { key: "sheering_status",   name: "Tosquia" },
            { key: "ph_average",        name: "Média do pH" },
            { key: "temperature_avg",   name: "Média da Temperatura" },
            { key: "slaughter_date",    name: "Data de Abate" },
            { key: "supplier_id",       name:"Responsável pelo Cadastro"},
            { key: "slaughter_plant",   name: "Planta de Abate" },
            { key: "processment_plant", name: "Planta de Processamento" },
            { key: "farm",              name: "Fazenda" },
            { key: "productor",         name: "Produtor" },
            { key: "deaths",            name: "Óbitos" },
            { key: "question_one",      name: "Conformidade de Permanência Mínima" },
            { key: "question_two",      name: "Conformidade de Ganho Mínimo de 100 g/dia" },
            { key: "question_three",    name: "Conformidade de Tempo de Jejum Alimentar" },
            { key: "question_four",     name: "Conformidade de Acesso a Água" },
            { key: "question_five",     name: "Conformidade de Tempo de Trânsito" },
            { key: "observation",       name: "Observação" },
        ]
};

export default Constants;
