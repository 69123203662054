
let initialState = {
    registering: false,
    id : null,
    processment_plants: {},
    slaughter_plants: {},
    animals_quantity: null,
    avg_live_weight: null,
    shearing_status: null,
    ph_average: null,
    hang_method:null,
    temperature_average:null,
    max_age_declared:null,
    slaughter_date: '',
    creation_date:null,
    status: null,
    evaluation_status:null,
    productor:{},
    isDorper: null,
    supplier: {},
    farm: {},
    compliance:{},
    compliance_id:null,
    races: [],
    deaths: null,
    observation: null,
    batchWillReciveUpdate: true,
    media:[],
    media_id:null
}



export default function batch(state = initialState, action){
    switch(action.type) {
        case '@batch/SET_BATCH_UPDATE':  
            return{...state, ...action.payload}; 
        case '@batch/BATCH_EVALUATION':
            return { ...state, ...action.payload}
        case '@batch/RESET':
            return { ...initialState }
        case '@batch/LOGOUT':
            return initialState 
        default:
            return state;   
    }
}

