import Axios from 'axios';
import Cookies from 'js-cookie';

const url = () =>{
    switch(process.env.REACT_APP_ENVIROMENT){
        case 'prod':
            return "https://meatgrades.click:3009/"
        case 'homol':
            return "https://meatgradesapp.click:3008/"    
        case 'local':
            return 'http://127.0.0.1:3333/'
        default:
            return 'http://127.0.0.1:3333/'
    }
}

const API = Axios.create({
    baseURL:url()
});

API.interceptors.request.use((config) => {
    const token = `Bearer ${Cookies.get('sheep-user-token')}`;
    config.headers.Authorization = token;
    return config;
});

export default API;
