import React, {useState} from 'react';

import Logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router';
import { 
    Container, 
    Input, 
    LoginButton, 
    LoginContainer, 
    LogoContainer, 
    Title, 
    TitleContainer, 
    StatusSpan, 
    Spinner,
    ModalContainer,
    ModalTitle,
    ModalBodyParagraph,
    ModalButton,
    ModalForm,
    PrivacyPolicy
} from './styles';
import API from '../../services/API/index';
import { object, string } from 'yup';
import Cookie from 'js-cookie';
import { useWarn } from "../../context/Modal"; 
import PolicyBody from '../../components/PrivacyPolicy/PrivacyBody';

export default function Login(){

    // useEffect(()=>{
    //     console.log(window.innerWidth,window.innerHeight )
    // },[])

    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({ username:'', password:'' })
    const [loginStatus, setLoginStatus] = useState(
        'Preencha nome de usuário e senha para prosseguir.'
    );
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false)
    const [terms, setTerms] = useState(false)
    const { warn } = useWarn();
   

    function saveUserDataOnCookie({
        token,
        id,
        name,
        fridgeId,
        fridgeName,
        companyId,
        technician
    }) {

        Cookie.set('sheep-user-token', token, { expires: 15 });
        Cookie.set('sheep-user-id', id, { expires: 15 });
        Cookie.set('sheep-user-name', name, { expires: 15 });
        Cookie.set('sheep-fridge-data', fridgeId, { expires: 15 });
        Cookie.set('sheep-fridge-name', fridgeName, { expires: 15 });
        Cookie.set('sheep-company-id', companyId, {expires: 15});
        Cookie.set('sheep-technician', technician.id, {expires:15});
        Cookie.set('sheep-cadastro-accepted-term', true, { expires: 700 });
    }

    function openPrivacyModal(){
        warn({ title: "Política Geral de Privacidade", component:<PolicyBody/>, size:400,showCancelButton:false, secondButton:{label:'Fechar', color:'#275224'} })
    }

    function goToBatches(){
        navigate('/batches')
    }

    const logIn = async () => {
        try {
            setIsLoading(true)
            setVisible(false)

            const schema = object({
                username: string().required('Nome de usuário obrigatório'),
                password: string().required(' Senha obrigatória')
            })

            await schema.validate(
                {username: userInfo.username, password:userInfo.password},
                {abortEarly:false}
            )
            
            await API.post('/users/authenticate/register',userInfo).then((res)=>{
                
                    let data = {
                        token: res.data.token,
                        name: res.data.name,
                        fridgeId: res.data.fridge.id,
                        fridgeName: res.data.fridge.name,
                        username: res.data.username,
                        processmentPlantStatus: res.data.processmentPlant.active,
                        companyId: res.data.fridge.company_id,
                        technician: res.data.technician,
                        id: res.data.id

                    }
                    

                    if(data.processmentPlantStatus){
                        setIsLoading(false)
                        if(Cookie.get('sheep-cadastro-accepted-term') == 'true'){
                            saveUserDataOnCookie(data)
                            goToBatches()
                        }else{
                            saveUserDataOnCookie(data)
                            setTerms(true)
                            saveUserDataOnCookie(data)
                        }
                    }
            })

        } catch (error) {
            
            if(error.name == "ValidationError"){
                if(error.errors.length > 1){
                    setLoginStatus('Preencha o usuário e a senha')
                }else{
                    setLoginStatus(error.errors)
                }
            }else if(error.response.status == 500){
                setLoginStatus("Credenciais Incorretas")
                
                
            }else if(error.response.status == 401){
                setLoginStatus("Acesso não permitido")
            }
            setVisible(true)
            setIsLoading(false)
            setTerms(false)
            
        }

    }

    return(
        <Container as='form' onSubmit={(e) => { e.preventDefault(); logIn();}} >
            {
                terms ?
                <ModalContainer >
                    <ModalTitle>
                        Termos e condições de uso
                    </ModalTitle>
                    <ModalForm>
                        <ModalBodyParagraph>
                            Ao usar nosso sitema você concorda com nossa&nbsp;
                            <PrivacyPolicy onClick={()=>{
                                openPrivacyModal()
                            }}>
                                <a style={{cursor: 'pointer'}}>
                                    <u>Política de Privacidade</u>
                                </a>
                            </PrivacyPolicy>
                            &nbsp; e com a coleta de dados pessoais necessário
                            bem como a utilização de cookies.
                        </ModalBodyParagraph>
                        <ModalButton
                            onClick={() => {
                                goToBatches()
                            }}
                            type="submit"
                        >
                            Estou de acordo
                        </ModalButton>
                    </ModalForm>
                </ModalContainer>
                
                :

                <LoginContainer>
                    <LogoContainer>
                        <img height={80} src={Logo} alt='logo'/>
                    </LogoContainer>
                    <TitleContainer>
                        <Title className='small'>
                            Sheep Cadastro
                        </Title>
                    </TitleContainer>
                    <Input 
                        placeholder='Usuário'
                        value={userInfo.username} 
                        onChange={(e) => setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            username: e.target.value,
                        }))}
                    />     
                    <Input 
                        type="password" 
                        placeholder='Senha'
                        value={userInfo.password} 
                        onChange={(e) => setUserInfo((prevUserInfo) => ({
                            ...prevUserInfo,
                            password: e.target.value,
                        }))}
                    />
                    {
                        visible ?
                        <StatusSpan>{loginStatus}</StatusSpan>
                        :
                        <></>
                    }
                    <LoginButton type='submit' className='medium'>
                            {
                            isLoading ?
                                <Spinner/>
                            :
                            <>
                                Entrar
                            </>
                            }
                    </LoginButton>
                </LoginContainer>            
            }
            
        </Container>
    )

}
