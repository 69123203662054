import React, { useState, useRef, useEffect } from 'react';
import { BatchContainer, IconsContainer, Item, ItemContainer, StatusItem, StatusContainer, ItemTitle, EvaluationContainer,
        EvaluationStatus, Evaluation, EvaluationBorder, EvaluationIcon, BatchStatus, BatchEvaluationContainer, Modal, Text, IconWrapper, DeleteModal,
        ModalHeader, ModalActions, ModalContainer, ModalContent, ContentContainer, CancelButton, ExcludeButton, CheckIcon, XIcon, DorperImage,
        BorderContainer, ThreeDotsIcon } from '../styles';

import { IoArrowForwardCircle, IoWarning } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";
import { FaCircleXmark, FaCircleExclamation } from "react-icons/fa6";
import { TbNotes } from "react-icons/tb";
import { GoTrash } from "react-icons/go";
import { PiNotePencil, PiDownload, PiFileImageFill } from "react-icons/pi";
import Popup from 'reactjs-popup';
import dorper from '../../../assets/images/dorperIcon.png'

export default function DesktopBatches({softDeleteBatch, batches, downloadBatchExcel, handleNavigateToBatch, handleNavigateToEvaluation, handleSummary, downloadMedia}){
    
    const [open, setOpen] = useState(false);

    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );

    const modalRef = useRef();
        

    const clearLocalStorage = () => {
        localStorage.removeItem('batchState');
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            clearLocalStorage();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      
    const handleCloseModal = (e) => {
        if(modalRef.current && !modalRef.current.contains(e.target))
        setOpen(false)
    }


    return(
        <>
            {batches.length > 0 && batches.map((info) => (
                <>

                        {(info.status !== 'FINISHED' && info.status !== 'SENT') || info.evaluation_status === '' ? 
                            <>
                            <div style={{display: 'flex',alignContent:'flex-end', justifyContent:'flex-end', marginBottom:'.2%', marginRight:'.3%'}}>
                                {info.isDorper ? <DorperImage src={dorper} alt='dorper' dorper={info.isDorper}/>: <></>}
                            </div>
                                <EvaluationContainer>
                                    <BorderContainer dorper={info.isDorper}>
                                        <BatchContainer>
                                            <ItemContainer>
                                                <ItemTitle className='medium'>ID Lote</ItemTitle>
                                                <Item className='small'>{info.id}</Item>    
                                            </ItemContainer>

                                            <ItemContainer>
                                                <ItemTitle className='medium'>ID Indústria</ItemTitle>
                                                <Item className='small'>{info.industry_batch ? info.industry_batch : '-'}</Item>    
                                            </ItemContainer>

                                            <ItemContainer>
                                                <ItemTitle className='medium'>Planta de Abate</ItemTitle>
                                                <Item className='small'>{info.slaughter_plants_name}</Item>
                                            </ItemContainer>

                                            <ItemContainer style={{display:'flex'}}>
                                                <ItemTitle className='medium'>{window.innerWidth > 1200 ? 'Processamento' : 'Process.'}</ItemTitle>
                                                <Item className='small'>{info.processment_plants_name}</Item>
                                            </ItemContainer>                                    

                                            <ItemContainer>
                                                <ItemTitle className='medium'>{window.innerWidth > 1200 ? 'Quantidade' : 'Qtd.'}</ItemTitle>
                                                <Item className='small'>{info.animals_quantity}</Item>
                                            </ItemContainer>

                                            <ItemContainer>
                                                <ItemTitle className='medium'>Abate</ItemTitle>
                                                <Item className='small'>{info.slaughter_date}</Item>
                                            </ItemContainer>
                                            
                                            <ItemContainer>
                                                <ItemTitle className='medium'>Cadastro</ItemTitle>
                                                <Item className='small'>{info.creation_date}</Item>
                                            </ItemContainer>

                                            <StatusContainer status={info.evaluation_status}>
                                                {info.evaluation_status === 'Aprovado' ? <CheckIcon/> :
                                                info.evaluation_status === 'Reprovado' ? <FaCircleXmark size={40} style={{color:'#C93131'}}/> :
                                                info.evaluation_status === 'Rascunho'? <ThreeDotsIcon/>:
                                            <FaCircleExclamation size={40} style={{color:'#275224'}}/>}

                                                <div style={{width:'100%'}}>
                                                    <StatusItem className='medium'>{info.evaluation_status}</StatusItem>
                                                </div>
                                            </StatusContainer>               
                                        <Popup
                                            trigger={
                                                <IconsContainer portrait={isPortrait}>
                                                    <HiDotsVertical size={30} style={{color:'#275224', cursor:'pointer'}} />
                                                </IconsContainer>  
                                            }
                                            position={['top center', 'bottom center']}
                                            closeOnDocumentClick
                                            keepTooltipInside=".tooltipBoundary"
                                            closeOnEscape={true}
                                        >
                                            {
                                                close => (
                                        
                                            <Modal>
                                                <Text className='small' onClick={()=>{close(); handleSummary(info)}}>
                                                    <IconWrapper className='icon'>
                                                        <TbNotes  style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                        Resumo
                                                </Text>
                                            
                                                <Text className='small' onClick={() =>{handleNavigateToBatch(info); close()}} >
                                                    <IconWrapper className='icon'>
                                                        <PiNotePencil style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                        Editar
                                                </Text>
                                                <Text className='small' onClick={() => setOpen(o => !o)}>
                                                                <IconWrapper className='icon' >
                                                                    <GoTrash style={{marginRight:'5px'}}/>
                                                                </IconWrapper>
                                                                    Excluir
                                                </Text>
                                                    
                                                    {open && (                             
                                                        <ModalContainer onClick={handleCloseModal}>
                                                            <DeleteModal ref={modalRef}> 
                                                                <ContentContainer>
                                                                    <IoWarning size={40} style={{color:'#D8190f'}}/>                                                   
                                                                    <ModalHeader className='medium'> Excluir lote </ModalHeader>
                                                                    <ModalContent className='small'>Você tem certeza que deseja excluir este lote? Essa ação não poderá ser desfeita!</ModalContent>
                                                                    <ModalActions>
                                                                        <CancelButton
                                                                            className="button"
                                                                            style={{marginRight:'50px'}}
                                                                            onClick={() => {setOpen(false); close();}}
                                                                        >
                                                                            Cancelar
                                                                        </CancelButton>
                                                                        <ExcludeButton
                                                                            className="button"
                                                                            onClick={() => {setOpen(false);softDeleteBatch(info); close();}}
                                                                        >
                                                                            Excluir
                                                                        </ExcludeButton>
                                                                    </ModalActions>
                                                                </ContentContainer>
                                                            </DeleteModal>
                                                        </ModalContainer>
                                                    )
                                                    }
                                                { info.evaluation_status !== 'Rascunho'?
                                                <>
                                                <Text className='small' onClick={()=>{downloadBatchExcel(info.id)}}>
                                                    <IconWrapper className='icon'>
                                                        <PiDownload style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                        Relatório
                                                </Text>
                                                {info.media&&(
                                                    <Text className='small' onClick={()=>downloadMedia(info.media)}>
                                                        <IconWrapper className='icon'>
                                                            <PiFileImageFill style={{marginRight:'5px'}}/>
                                                        </IconWrapper>
                                                        Mídia
                                                    </Text>)
                                                }
                                            </>
                                                :
                                                <></>}
                                            </Modal>
                                        )
                                        }
                                        </Popup>

                                        </BatchContainer>
                                        <div style={{width:'20px', backgroundColor:'#0000000D', height:'110px'}}></div>
                                        <BatchStatus>

                                            {info.evaluation_status === 'Reprovado' || info.evaluation_status === 'Rascunho'? <IconsContainer></IconsContainer> :
                                                <ItemContainer onClick={() =>handleNavigateToEvaluation(info)}>
                                                    <IoArrowForwardCircle size={60} style={{color:'#275224', cursor:'pointer'}}/>
                                                </ItemContainer>
                                            }    
                                        </BatchStatus> 
                                    </BorderContainer>   
                                </EvaluationContainer>                
                            </>                       
                        : 
                        <> 
                            <div style={{display: 'flex', alignContent:'flex-end', justifyContent:'flex-end', marginBottom:'.2%', marginRight:'.3%'}}>
                                {info.isDorper ? <DorperImage src={dorper} alt='dorper' dorper={info.isDorper}/>: <></>}
                            </div>
                            <EvaluationContainer>
                                <BatchEvaluationContainer dorper={info.isDorper}>
                                    <ItemContainer>
                                        <ItemTitle className='medium'>ID Lote</ItemTitle>
                                        <Item className='small'>{info.id}</Item>    
                                    </ItemContainer>

                                    <ItemContainer>
                                        <ItemTitle className='medium'>ID Indústria</ItemTitle>
                                        <Item className='small'>{info.industry_batch ? info.industry_batch : '-'}</Item>    
                                    </ItemContainer>

                                    <ItemContainer>
                                        <ItemTitle className='medium'>Planta de Abate</ItemTitle>
                                        <Item className='small'>{info.slaughter_plants_name}</Item>
                                    </ItemContainer>

                                    <ItemContainer style={{display:'flex'}}>
                                        <ItemTitle className='medium'>{window.innerWidth > 1200 ? 'Processamento' : 'Process.'}</ItemTitle>
                                        <Item className='small'>{info.processment_plants_name}</Item>
                                    </ItemContainer>                               

                                    <ItemContainer>
                                        <ItemTitle className='medium'>{window.innerWidth > 1200 ? 'Quantidade' : 'Qtd.'}</ItemTitle>
                                        <Item className='small'>{info.animals_quantity}</Item>
                                    </ItemContainer>

                                    <ItemContainer>
                                        <ItemTitle className='medium'>Abate</ItemTitle>
                                        <Item className='small'>{info.slaughter_date}</Item>
                                    </ItemContainer>
                                    
                                    <ItemContainer>
                                        <ItemTitle className='medium'>Cadastro</ItemTitle>
                                        <Item className='small'>{info.creation_date}</Item>
                                    </ItemContainer>

                                    <StatusContainer status={info.evaluation_status}>
                                        {info.evaluation_status === 'Aprovado' ? <CheckIcon/> :
                                        info.evaluation_status === 'Reprovado' ? <XIcon/> :
                                        info.evaluation_status === 'Rascunho'? <ThreeDotsIcon size={40}/>:
                                        <FaCircleExclamation size={40} style={{color:'#275224'}}/>}

                                        <div style={{width:'100%'}}>
                                            <StatusItem className='medium'>{info.evaluation_status}</StatusItem>
                                        </div>
                                    </StatusContainer>               

                                    <Popup
                                        trigger={
                                            <IconsContainer portrait={isPortrait}>
                                                <HiDotsVertical size={30} style={{color:'#275224', cursor:'pointer'}} />
                                            </IconsContainer>  
                                        }
                                        position={['top center', 'bottom center']}
                                        closeOnDocumentClick
                                        keepTooltipInside=".tooltipBoundary"
                                        closeOnEscape={true}
                                            >
                                                {close => (
                                        <Modal>
                                            <Text className='small' onClick={()=>{close(); handleSummary(info)}}>
                                                <IconWrapper className='icon'>
                                                    <TbNotes style={{marginRight:'5px'}} />
                                                </IconWrapper>
                                                    Resumo
                                            </Text>
                                            {info.status !== 'FINISHED' && info.status !== 'SENT' && (  
                                                    <>
                                                <Text className='small' onClick={() =>{handleNavigateToBatch(info); close()}} >
                                                    <IconWrapper className='icon'>
                                                        <PiNotePencil style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                        Editar
                                                </Text>
                                                <Text className='small' onClick={() => setOpen(o => !o)}>
                                                                <IconWrapper className='icon' >
                                                                    <GoTrash style={{marginRight:'5px'}}/>
                                                                </IconWrapper>
                                                                    Excluir
                                                </Text>

                                                </>                  
                                            )}
                                        
                                            {open && (                             
                                                    <ModalContainer onClick={handleCloseModal}>
                                                        <DeleteModal ref={modalRef}>                                                    
                                                        <ContentContainer>
                                                                <IoWarning size={40} style={{color:'#D8190f'}}/>                                                   
                                                                <ModalHeader className='medium'> Excluir lote </ModalHeader>
                                                                <ModalContent className='small'>Você tem certeza que deseja excluir este lote? Essa ação não poderá ser desfeita!</ModalContent>
                                                                <ModalActions>
                                                                    <CancelButton
                                                                        className="button"
                                                                        style={{marginRight:'50px'}}
                                                                        onClick={() => {setOpen(false); close()}}
                                                                    >
                                                                        Cancelar
                                                                    </CancelButton>
                                                                    <ExcludeButton
                                                                        className="button"
                                                                        onClick={() => {setOpen(false);softDeleteBatch(info); close()}}
                                                                    >
                                                                        Excluir
                                                                    </ExcludeButton>
                                                                </ModalActions>
                                                            </ContentContainer>
                                                        </DeleteModal>
                                                    </ModalContainer>
                                                )                                               
                                                }
                                                { info.evaluation_status !== 'Rascunho'?
                                            <>
                                                <Text className='small' onClick={()=>{downloadBatchExcel(info.id)}}>
                                                    <IconWrapper className='icon'>
                                                        <PiDownload style={{marginRight:'5px'}}/>
                                                    </IconWrapper>
                                                        Relatório
                                                </Text>
                                            {info.media.lenght > 0 && (
                                                    <Text className='small' onClick={()=>downloadMedia(info.media)}>
                                                        <IconWrapper className='icon'>
                                                            <PiFileImageFill style={{marginRight:'5px'}}/>
                                                        </IconWrapper>
                                                        Mídia
                                                    </Text>)
                                                }
                                        </>
                                            : <></>}
                                        </Modal>
                                        
                                        )}
                                    </Popup>                   

                                </BatchEvaluationContainer>

                                <EvaluationStatus dorper={info.isDorper}>
                                    {info.status === 'FINISHED' ?
                                        <Evaluation>
                                            <EvaluationBorder status={info.status}>
                                                Finalizado
                                            </EvaluationBorder>
                                            <EvaluationIcon onClick={() =>handleNavigateToEvaluation(info)}>
                                                    <IoArrowForwardCircle size={45} style={{color:'rgba(8, 117, 1)', marginTop:'5px', cursor:'pointer'}}/>
                                            </EvaluationIcon>
                                        </Evaluation>
                                        : 
                                            <Evaluation>
                                                <EvaluationBorder status={info.status}>
                                                    Andamento
                                                </EvaluationBorder>
                                                <EvaluationIcon onClick={() =>handleNavigateToEvaluation(info)}>
                                                    <IoArrowForwardCircle size={45} style={{color:'F1C62E', cursor:'pointer'}}/>
                                                </EvaluationIcon>
                                            </Evaluation>
                                    }
                                    
                                </EvaluationStatus>
                            </EvaluationContainer>
                        </>
                        }  
                </>
            ))}
        </>
    )

}
