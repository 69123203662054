import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: -1;
  }
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 30%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y:auto;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  color: #275224;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direciton: row;
  justify-content: space-between;
  align-itens: center
  width:100%
`

export const FormGroup = styled.div`
  display:flex;
  flex-direction: column;
  width: 95%;
  margin: 0.5%;
`;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  margin-left: 4px;
  color: #275224;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const FormSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const SubmitButton = styled.button`
  background-color: #275224;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  margin-left: 10px;
`;

export const CancelButton = styled.button`
  background-color: #ccc;
  color: black;
  padding: 10px 20px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
