import styled from "styled-components";

export const Container = styled.div`
    width:100%;
    height:100%;
`

export const ContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
` 

export const Title = styled.div`
    font-size:32px;
    color:#275224;
`

export const InputsContainer = styled.div`
    width:100%;
    display:flex;
    align-items:start;
    justify-content:start;
    margin-top:30px;
    font-size:16px;
`

export const ItemsContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    margin-right:50px;
`

export const InputTitle = styled.div`
    color:#275224;
    width:100%;
`

export const Input = styled.input`
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    font-size:20px;
    margin-top:5px;
    padding-left:10px;
`

export const SheetButton = styled.input`
    height:100%;
    width:300px;
    border:none;
    border-radius:5px;
    font-size:14px;
    color:#575757;
    margin-right:20px;
    
`

export const SheetText = styled.span`
    font-size:16px;
    color:#575757;
`

export const SheetLink = styled.a`
    font-size:16px;
    color:#2086B1;
    text-decoration:underline;
    cursor:pointer;
`

export const FileContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:22px;
    margin-top:5px;
    cursor:pointer;
`
export const WarningContainer = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
    margin-top:${props =>props.warning ? '20px' : '10px'};
    margin-bottom: 10px;
`


export const WarningContent = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    
`

export const SearchInput = styled.input`
    height:14px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    font-size:16px;
    margin-top:5px;
    padding:10px;
    width:150px;
`

export const TableHeader = styled.div`

    display: grid;
    grid-template-columns: 1.1fr 0.95fr repeat(9, 0.995fr) 1fr .63fr;
    grid-row-gap: 2px;
    margin:15px;
    justify-content:space-around;
    text-align:center;

    @media (max-width:1600px){
        grid-template-columns: 0.4fr 0.75fr 0.8fr 0.7fr 0.6fr 1fr 1fr 0.5fr repeat(3, 1.001fr) 0.3fr 0.3fr;
    }    
`

export const TableContainer = styled.div`
    width:100%;
    max-height:450px;
    overflow-y:auto;
    -webkit-scrollbar {
        display: none;
      }
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius:20px;
`

export const TableContent = styled.div`
    background-color:#F3F3F3;
    border-radius:20px;
    padding:2px 0;
    margin-bottom:20px;
    min-height:150px;  
    @media (max-height:591px){
        max-height: 250px;
    }
    @media (max-height:903px){

        max-height: 250px;
        @media(max-width: 2490px){
            max-height: 390px
        }
    }
    overflow-y:auto;  
`

export const ListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr) .6fr;
    padding: 8px 0px;
    background-color:#D9D9D9;
    margin:20px;
    border-radius:5px;
    align-items:center;
    justify-content:center;
    overflow-y: auto;
    

    @media (max-width:1600px){
        grid-template-columns: 0.3fr 0.8fr 0.8fr 0.7fr 0.6fr 1fr 1fr 0.5fr repeat(3, 1fr) 0.3fr 0.3fr;
    }    
`

export const ListItem = styled.input`
    text-align:center;
    color:#575757;
    font-size:13px;
    margin-top:1px;
    border:none;
    background-color:transparent;
    width:100%;
    outline:none;
    padding:20px 0px;

    @media (max-width:1400px){
        font-size:13px;
    }
`

export const Select = styled.select`

-webkit-appearance: none;
width:5ch;
padding:3px;
background-color:transparent;
border:solid;
border-width: 0px 5px 0px 0px;
border-color:#575757;
font-size:14px;
font-weight:bold;
color:#575757;

`

export const TableTitle = styled.div`
    color:#275224;
    white-space: nowrap;
    text-align:center;
    font-size:16px;

    @media (max-width:1400px){
        font-size:13px;
    }
`
export const ButtonsContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
    width:100%;
    margin:5px 0 40px 0;
    
`

export const ModalItensContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content:center;
    background: rgba(0, 0, 0, 0.3);
    overflow: auto;
    z-index: 1000;
`