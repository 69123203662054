import styled, { css } from "styled-components";

export const Container = styled.div`
    width:100%;
    height:100%;
`

export const Title = styled.div`
    font-size:32px;
    color:#275224;
`

export const InputsContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:30px;
    font-size:16px;
`

export const SmallerInput = styled.div`
    width:40%;
    display:flex;
    flex-direction:column;
`

export const BiggerInput1 = styled.div`
    width:60%;
    display:flex;
    flex-direction:column;
`

export const BiggerInput2 = styled.div`
    width:30%;
    display:flex;
    flex-direction:column;
`

export const InputTitle = styled.div`
    color:#275224;
    width:100%;
`

export const Input = styled.input`
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px;
    ${p => p.error?css`
    border:solid;
    border-width: 2px;
    border-color: #EE4245;
    `
    :
    ''
    }
    font-size:20px;
    margin-top:5px;
    padding-left:10px;
    color:#575757;

`

export const Select = styled.select`

    -webkit-appearance: none;

    color: #575757;
    height:45px;
    background-color:#F0F0F0;
    border:none;
    border-radius:5px 0 0 5px;

    ${p => p.error?css`
    border:solid;
    border-width: 2px 0px 2px 2px;
    border-color: #EE4245;
    `
    :
    ''
    }

    font-size:20px;
    margin-top:5px;
    padding-left:10px;  
    width:100%;
    outline:none;

    @media (max-width:1400px){
        font-size:16px;
    }
`

export const InputDivisionLeft = styled.div`
    display:flex;
    flex-direction:column;
    width:45%;
    padding-right:5%;
`

export const InputDivisionRight = styled.div`
    display:flex;
    flex-direction:column;
    width:50%;
`

export const SmallersContainer= styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:30px;
`

export const BiggerContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:30px;
`

export const CompliancesContainer = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
`

export const CompliancesItems = styled.div`
    display:flex;
    width:100%;
    padding-bottom:20px;
`

export const CompliancesText = styled.div`
    font-size:18px;
    font-weight:bold;
    color:#575757;
    width:91%;
`

export const CheckboxContainer = styled.div`
  vertical-align: middle;
  cursor:pointer;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${props =>{ 
    return (props.checked ? '#275224' : 'transparent')
}};
  border-radius: 50%;
  transition: all 150ms;
  border: 2px solid ${p=> p.disabled? '#575757':"#275224"};
  cursor:pointer;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }

`

export const CompliancesInputsContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:30px;
    font-size:16px;
    padding-bottom:40px;

    @media screen and (max-width:1600px){
        margin-top:15px;
    }
    
`

export const CompliancesDeaths = styled.div`
    display:flex;
    flex-direction:column;
    width:15%;
`

export const CompliancesObservation = styled.div`
    display:flex;
    flex-direction:column;
    width:54.5%;
    padding-left:3%;
`

export const NextButton = styled.button`
    height:45px;
    border:none;
    border-radius:5px;
    font-size:20px;
    margin-top:20px;
    background-color:#275224;
    width:17%;
    cursor:pointer;
    color:#FFF;
`

export const FakeContainer = styled.div`
    display:flex;
    align-items:center;
`

export const FakeBorder = styled.div`
width:10px;
background-color:#D9D9D9;
height:43px;
margin-top:5px;
border-radius:0 5px 5px 0;

${p => p.error?css`
    border:solid;
    border-width: 2px 2px 2px 0px;
    border-color: #EE4245;
    height:41px;
    `
    :
    ''
    }


`

export const AddNewProductor = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
`

export const AddProductorButton = styled.button`
    margin-top: 5px;
    margin-left: 8px;
    display: flex;
    border:none;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius:5px;
    font-size:22px;
    background-color:#275224;
    width:40px;
    cursor:pointer;
    color:#FFF;
`

export const AddNewFarm = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
`

export const AddFarmButton = styled.button`
    margin-top: 5px;
    margin-left: 8px;
    display: flex;
    border:none;
    justify-content: center;
    align-items: center;
    height: 43px;
    border-radius:5px;
    font-size:22px;
    background-color:#275224;
    width:43px;
    cursor:pointer;
    color:#FFF;
`