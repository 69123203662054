import AWS from 'aws-sdk';

// Configure o SDK com suas credenciais e região
AWS.config.update({
    accessKeyId: 'AKIARPHWGINGN6BVMAU7',
    secretAccessKey: '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',
    region: 'sa-east-1',
});

// Crie uma instância de serviço AWS, por exemplo, S3
export const S3 = new AWS.S3();
