import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Login from "./pages/Login/index.js";
import Batches from "./pages/Batches/index.js";
import Report from "./pages/Report/index.js";
import Root from "./routes/root";
import Register from "./pages/Register";
import './index.css';
import Evaluation from "./pages/Evaluation";
import { Warn } from "./context/Modal";
import {Provider} from 'react-redux'
import { store } from "./store";

const router = createBrowserRouter([
  {
    path: "",
    element: <Login/>
  },
  {
    path: "/batches",
    element: <Root isSelected='batches'/>,
    children: [
        {
          path: "",
          element: <Batches />,
        },
      ],
  },
  {
    path: "/register",
    element: <Root isSelected='register'/>,
    children: [
        {
          path: "",
          element: <Register/>,
        },
      ],
  },
  {
    path: "/report",
    element: <Root isSelected='report'/>,
    children: [
        {
          path: "",
          element: <Report/>,
        },
      ],
  },
  {
    path: "/Evaluation/*",
    element: <Root isSelected='evaluation'/>,
    children: [
        {
          path: "",
          element: <Evaluation />,
        },
      ],
  },
], { basename:'/cadastro' });

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <Warn>
        <RouterProvider router={router} />
      </Warn>
    </Provider>
);