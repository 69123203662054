import React, { useEffect, useState, useRef} from 'react';
import { AnimalContainer, AnimalContent, AnimalText, AnimalTitle, BackButtonContainer, Button, ButtonContainer, ButtonText, ButtonValue, CancelButton, CaveatContainer, CaveatText,
         CaveatTitle, ClassificationContainer, DropdownContainer, DropdownContent, DropdownContentContainer, DropdownHeader, GridButtom, GridButtonsContainer, GridButtonsPhGrContainer,
         GridColumn, GridTitle, IdContent, InfoIcon, InfoModal, InfoModalContainer, ModalActions, ModalContent, ModalHeader, ModalItensContainer, ModalTextContainer, ModalTitle, StarIcon,
         StatusContainer, StatusText, StyledArrowIcon, StyledBackArrowIcon, SubtitleContainer, SubtitleText, SubtitleTitle, TextContainer, Title, TitleContainer, TitleInfo } from '../styles';
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

// REDUX
import ModalEvaluations from '../modal';
import Helpers from '../../../services/helpers';


export default function LandscapeLayout({sendUpdate, animalList, softDeleteAnimal, loading, renderGrNumberButtons, handleMinusClick, batchId, updateAnimal,
    handlePlusClick, header, estadoBotoes, handleMarblingButtonClick, handleTemperatureButtonClick, handlePhMinusClick, handlePhPlusClick,
    renderPhNumberButtons, temperatureTransformation, selectedAnimalId, setSelectedAnimalId, saveToLocalStorage}){

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [currentAnimal, setCurrentAnimal] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(null);

    const modalRef = useRef();

    const [isPortrait, setIsPortrait] = useState(
        window.innerHeight > window.innerWidth
    );


    useEffect(() => {
        const handleBeforeUnload = () => {
            saveToLocalStorage(batchId);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [batchId, saveToLocalStorage]);

    
    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    const handleCloseModal = (e) => {
        setOpen(false)
    }

    const handleUpdateAnimal = () => {
        if(currentAnimal){
            updateAnimal(currentAnimal);
            setOpen(false);
        }
    };

    const handleClick = (id) => {
        setSelectedAnimalId(id === selectedAnimalId ? null : id);
    };

    const handleSquareClick = (e) => {
        e.stopPropagation();
    };

    
    const handleInfoModalOpen = (status, index) => {
        if(status === 'FINISHED' || status === 'INAPT'){
            setInfoModalOpen(index)
        }
    }

    const handleInfoModalClose = (e) => {
        if(modalRef.current && !modalRef.current.contains(e.target))
        setInfoModalOpen(false)
    }

    
    const handleOpenModal = (animal) => {
        setCurrentAnimal(animal);
        setOpen(true);
    };


    if (loading) {
        return <div>Carregando...</div>;
    }


    return(

                <>

                    <ModalEvaluations
                    animal={currentAnimal}
                    handleCloseModal={handleCloseModal} 
                    setOpen={setOpen}
                    open={open}
                    handleUpdateAnimal={handleUpdateAnimal}
                    />

                     <TitleContainer>
                         <TitleInfo>
                             <BackButtonContainer>
                                 <StyledBackArrowIcon color='#275224' onClick={()=>{navigate(-1)}}/>
                             </BackButtonContainer>
                             <Title className='large'>Avaliação</Title>
                             <Title/>
                         </TitleInfo>    
                         <div style={{display:'flex', width:'100%', justifyContent:'space-evenly', marginBottom:'10px'}}>
                            <SubtitleContainer>
                                <SubtitleTitle className='medium'>Lote</SubtitleTitle>
                                <SubtitleText className='medium'>{header.batch}</SubtitleText>
                            </SubtitleContainer>
                            {header.industryBatch !== 'null'?
                               <SubtitleContainer>
                                   <SubtitleTitle className='medium'>ID Indústria</SubtitleTitle>
                                   <SubtitleText className='medium'>{header.industryBatch}</SubtitleText>
                               </SubtitleContainer>
                               : ''
                            }
                            <SubtitleContainer>
                                <SubtitleTitle className='medium'>Abate</SubtitleTitle>
                                <SubtitleText className='medium'>{header.date}</SubtitleText>
                            </SubtitleContainer>
                            <SubtitleContainer>
                                <SubtitleTitle className='medium'>Produtor</SubtitleTitle>
                                <SubtitleText className='medium'>{header.productor}</SubtitleText>
                            </SubtitleContainer>
                         </div>
                     </TitleContainer>
                     {animalList.length > 0 && animalList.map((a, index)=>(
                        <>
                        {infoModalOpen === index && 
                            (
                                <InfoModalContainer onClick={handleInfoModalClose} key={index}>
                                    <InfoModal ref={modalRef} portrait={isPortrait}>
                                        <ModalItensContainer>
                                            <div style={{display:'flex', flexDirection:'row', width:'45%', justifyContent:'space-around', alignItems:'center', marginBottom:'10px'}}>
                                                <InfoIcon/>
                                                <ModalHeader>Informações do animal</ModalHeader>
                                            </div>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-around'}}>
                                                <div>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>ID: </ModalTitle>
                                                        <ModalContent>{a.animal.carcass_id}</ModalContent>
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>pH Inicial: </ModalTitle>
                                                        <ModalContent>{a.animal.initial_pH && a.animal.initial_pH !== -1 ? a.animal.initial_pH.toFixed(1).replace('.',',') : '-'}</ModalContent>
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>Temp. I.: </ModalTitle>
                                                    <ModalContent>{Helpers.VerifyTemperatureInterval(a.animal.initial_temp)}</ModalContent>    
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>Dentição: </ModalTitle>
                                                        <ModalContent>{a.animal.dentition}</ModalContent>
                                                    </ModalTextContainer>
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>PCQ (kg): </ModalTitle>
                                                        <ModalContent>{a.animal.carcass_weight?.toLocaleString('pt-BR') ? a.animal.carcass_weight?.toLocaleString('pt-BR') : '-'}</ModalContent>
                                                    </ModalTextContainer>   
                                                    <ModalTextContainer>
                                                        <ModalTitle className='medium'>Peso Vivo: </ModalTitle>
                                                        <ModalContent>{a.animal.alive_weight ? a.animal.alive_weight : '-'}</ModalContent>
                                                    </ModalTextContainer>     
                                                </div>
                                                <div>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>Sexo:</ModalTitle>
                                                    <ModalContent>{a.animal.sex === 'femea' ? 'fêmea' : a.animal.sex}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>pH Final: </ModalTitle>
                                                    <ModalContent>{a.evaluations.pH && a.evaluations.pH !== -1 ? a.evaluations.pH.toFixed(1).replace('.',',') : '-'}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>Temp. F.: </ModalTitle>
                                                    <ModalContent>{temperatureTransformation(a.evaluations.temperature)}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>Marmoreio: </ModalTitle>
                                                    <ModalContent>{a.evaluations.meat_marbling && a.evaluations.meat_marbling !== -1 ? a.evaluations.meat_marbling : '-'}</ModalContent>
                                                </ModalTextContainer>
                                                <ModalTextContainer>
                                                    <ModalTitle className='medium'>GR(mm): </ModalTitle>
                                                    <ModalContent>{a.evaluations.fat_thickness !== undefined && a.evaluations.fat_thickness !== null && a.evaluations.fat_thickness !== -1 && a.evaluations.fat_thickness !== '' ? a.evaluations.fat_thickness : '-'}</ModalContent>
                                                </ModalTextContainer>
                                                </div>
                                            </div>
                                            <ModalActions>
                                                <CancelButton onClick={() => handleInfoModalClose(a)}>Fechar</CancelButton>
                                            </ModalActions>
                                        </ModalItensContainer>
                                    </InfoModal>
                                </InfoModalContainer>
                            )}
                         <AnimalContainer
                         key={a.animal.id}
                         status={a.evaluations.status}
                         statusColor={a.evaluations.color}
                         isselected={a.animal.id === selectedAnimalId}
                         onClick={() => handleInfoModalOpen(a.evaluations?.status, index)}>
                             <DropdownContainer isselected={a.animal.id === selectedAnimalId} >
                                 <DropdownHeader onClick={() => a.evaluations.status === 'CREATED' || Object.keys(a.evaluations).length === 0 ? handleClick(a.animal.id):''} dorper={a.animal.dorper}>
                                     <div style={{display:'flex', height:'100%', width:'100%', justifyContent:'space-around', margin:'10px 0 30px 0'}}>
                                         <IdContent isselected={a.animal.id=== selectedAnimalId} status={a.evaluations.status} statusColor={a.evaluations.color}>
                                            <AnimalTitle style={{ fontSize:'20px', marginBottom:'2px'}}>{a.animal.carcass_id}</AnimalTitle>
                                         </IdContent>
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>pH Final</AnimalTitle>
                                             <AnimalText className='medium'>{a.evaluations.pH && a.evaluations.pH !== -1 ? a.evaluations.pH.toFixed(1).replace('.',',') : '-'}</AnimalText>
                                         </AnimalContent>
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>Temp. Final</AnimalTitle>
                                             <AnimalText className='medium'>{temperatureTransformation(a.evaluations.temperature)}</AnimalText>
                                         </AnimalContent>    
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>Marmoreio</AnimalTitle>
                                             <AnimalText className='medium'>{a.evaluations.meat_marbling && a.evaluations.meat_marbling !== -1 ? a.evaluations.meat_marbling : '-'}</AnimalText>
                                         </AnimalContent >
                                         <AnimalContent isselected={a.animal.id === selectedAnimalId}>
                                             <AnimalTitle className='medium'>GR(mm)</AnimalTitle>
                                             <AnimalText className='medium'>{a.evaluations.fat_thickness !== undefined && a.evaluations.fat_thickness !== null && a.evaluations.fat_thickness !== -1 && a.evaluations.fat_thickness !== '' ? a.evaluations.fat_thickness : '-'}</AnimalText>
                                         </AnimalContent >                           
                                     </div>
                                    
                                     {a.evaluations.color === 'blue' ? (
                                     <CaveatContainer isselected={a.animal.id === selectedAnimalId}>
                                             <>
                                                 <CaveatTitle className='medium'>Cortes aproveitados:</CaveatTitle>
                                                 <CaveatText className='medium'>{a.evaluations.cutting_meat === 0 ? 'Lombo, file mignon, picanha e alcatra' : 'picanha e alcatra'}</CaveatText>
                                             </>
                                     </CaveatContainer>
                                         ) : ''}
 
                                     <div style={{display:'flex', width:'100%', marginBottom:'10px', justifyContent:'space-around', marginLeft:'20px'}}>
                                         <StatusContainer status={a.evaluations.status} statusColor={a.evaluations.color} isselected={a.animal.id === selectedAnimalId}>
                                             {Object.keys(a.evaluations).length === 0 ? (
                                                 <>
                                                     <PiDotsThreeCircleFill size={25} color='#606773'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Pendente</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ) : a.evaluations.status === 'CREATED' ? (
                                                 <>
                                                     <PiDotsThreeCircleFill size={25} color='#F1C62E'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Andamento</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'blue' ? (
                                                 <>
                                                     <FaCircleCheck size={20} color='#4D7EC6'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Aprovado com Ressalvas</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'orange' ? (
                                                 <>
                                                     <FaCircleCheck size={20} color='#F1C62E'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Aprovado</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'green' ? (
                                                 <>
                                                     <FaCircleCheck size={20} color='#275224'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Aprovado</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): a.evaluations.color === 'red' ?(
                                                 <>
                                                     <FaCircleXmark size={20} color='#C93131'/>
                                                     <TextContainer>
                                                         <StatusText className='medium'>Desclassificado</StatusText>
                                                     </TextContainer>
                                                 </>
                                             ): ''
                                             }
                                         </StatusContainer>
                                        <ClassificationContainer style={{width:'250px'}}/>
                                        <ClassificationContainer isselected={a.animal.id === selectedAnimalId} portrait={isPortrait}>
                                            {a.evaluations.stars === 4 ? (
                                            <>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                            </>
                                            ) : a.evaluations.stars === 5 ? (
                                            <>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                                <StarIcon/>
                                            </>
                                            ) : Object.keys(a.evaluations).length === 0 ? <StyledArrowIcon color='#575757' isselected={a.animal.id === selectedAnimalId}/> : 
                                                a.evaluations.status === 'CREATED' ? <StyledArrowIcon color='#F1C62E' isselected={a.animal.id === selectedAnimalId}/> : ''}
                                        </ClassificationContainer>
                                     </div>
                                 </DropdownHeader>
                                 <DropdownContentContainer isselected={a.animal.id === selectedAnimalId}>
                                     
                                 <DropdownContent portrait={isPortrait}>
                                         <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                             <GridTitle className='medium'>Temperatura</GridTitle>
                                             <GridButtonsContainer portrait={isPortrait}>
                                                <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 0)
                                                     sendUpdate(a.animal.id, 'temperature', 0)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 0 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 0 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>0 ~ 3</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 1)
                                                     sendUpdate(a.animal.id, 'temperature', 1)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 1 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 1 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>3,1 ~ 6</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 2)
                                                     sendUpdate(a.animal.id, 'temperature', 2)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 2 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 2 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>6,1 ~ 9</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 3)
                                                     sendUpdate(a.animal.id, 'temperature', 3)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 3 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 3 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>9,1 ~ 12</ButtonValue>
                                                 </GridButtom>
                                                 <GridButtom onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleTemperatureButtonClick(a.animal.id, 4)
                                                     sendUpdate(a.animal.id, 'temperature', 4)}}
                                                     style={{backgroundColor: estadoBotoes[a.animal.id]?.temperatureSelected === 4 ? '#275224' : '#D9D9D9',
                                                         color:estadoBotoes[a.animal.id]?.temperatureSelected === 4 ? '#FFF' : '#000'}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>{'> 12'}</ButtonValue>
                                                 </GridButtom>
                                             </GridButtonsContainer>
                                         </GridColumn>
                                     
                                             <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                                 <GridTitle className='medium'>Marmoreio</GridTitle>
                                                 <GridButtonsContainer portrait={isPortrait}>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 1)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 1)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 1 ? '#275224' : '#D9D9D9',
                                                                 color:estadoBotoes[a.animal.id]?.marblingSelected === 1 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>1</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Ausente</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 2)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 2)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 2 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 2 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>2</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Pouco</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 3)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 3)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 3 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 3 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>3</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Moderado</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 4)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 4)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 4 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 4 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>4</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Elevado</ButtonText>
                                                     </GridButtom>
                                                     <GridButtom portrait={isPortrait} onClick={(e) => {
                                                         handleSquareClick(e)
                                                         handleMarblingButtonClick(a.animal.id, 5)
                                                         sendUpdate(a.animal.id, 'meat_marbling', 5)}}
                                                         style={{backgroundColor: estadoBotoes[a.animal.id]?.marblingSelected === 5 ? '#275224' : '#D9D9D9',
                                                             color:estadoBotoes[a.animal.id]?.marblingSelected === 5 ? '#FFF' : '#000'}}>
                                                         <ButtonValue portrait={isPortrait} className='medium'>5</ButtonValue>
                                                         <ButtonText portrait={isPortrait} className='medium'>Abundante</ButtonText>
                                                     </GridButtom>
                                                 </GridButtonsContainer>
                                             </GridColumn>
                                    </DropdownContent>
 
                                    <DropdownContent portrait={isPortrait}>
                                    <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                                    <GridTitle className='medium'>pH</GridTitle>
                                                    <GridButtonsPhGrContainer>
                                                        <GridButtom onClick={(e) => {
                                                            handleSquareClick(e)
                                                            handlePhMinusClick(a.animal.id)}}>
                                                            <ButtonValue portrait={isPortrait} className='medium'>-</ButtonValue>
                                                        </GridButtom>

                                                        {renderPhNumberButtons(a.animal.id)}
                                                        
                                                        <GridButtom onClick={(e) => {
                                                            handleSquareClick(e)
                                                            handlePhPlusClick(a.animal.id)}}>
                                                            <ButtonValue portrait={isPortrait} className='medium'>+</ButtonValue>
                                                        </GridButtom>
                                                    </GridButtonsPhGrContainer>
                                                </GridColumn>
                                    </DropdownContent>

                                    <DropdownContent portrait={isPortrait}>
                                         <GridColumn isselected={a.animal.id === selectedAnimalId}>
                                             <GridTitle className='medium'>GR(mm)</GridTitle>
                                             <GridButtonsPhGrContainer portrait={isPortrait}>
                                                 <GridButtom portrait={isPortrait} onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handleMinusClick(a.animal.id)}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>-</ButtonValue>
                                                 </GridButtom>
         
                                                 {renderGrNumberButtons(a.animal.id)}
                                                 
                                                 <GridButtom portrait={isPortrait} onClick={(e) => {
                                                     handleSquareClick(e)
                                                     handlePlusClick(a.animal.id)}}>
                                                     <ButtonValue portrait={isPortrait} className='medium'>+</ButtonValue>
                                                 </GridButtom>
                                             </GridButtonsPhGrContainer>
                                         </GridColumn>
                                    </DropdownContent>
 
                                    <ButtonContainer>
                                        <Button style={{marginRight:'2%', backgroundColor:'#d25650', borderColor:'#d25650'}} 
                                            onClick={()=>{
                                                softDeleteAnimal(a.animal.id,a.animal.carcass_id)
                                            }}
                                        >
                                            Excluir
                                        </Button>
                                        <Button
                                            onClick={(e) => {
                                                handleSquareClick(e)
                                                handleOpenModal(a)
                                                }}
                                        >
                                                Finalizar
                                        </Button>
                                    </ButtonContainer>
                                   
                                 </DropdownContentContainer> 
                             </DropdownContainer>
                         </AnimalContainer>
                        </>
                     ))}
                 </>
    )
}