import styled from "styled-components";

export const Container = styled.div`
    width:100vw;
    height:70vh;
    display:flex;
    align-items:center;
    justify-content:center;

    @media (max-width: 1000px){
        width:100%;        
        }
`

export const LoginContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100%;
`

export const LogoContainer = styled.div`
    display:flex;
    margin:10px;
`

export const TitleContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`

export const Title = styled.div`
    font-size:16px;
    color:#575757;
    margin-bottom:5px;
    white-space: nowrap;
`

export const Input = styled.input`
    width:20%;
    height:40px;
    background-color:#D9D9D9;
    border:none;
    margin:10px;
    border-radius:20px;
    font-size:16px;
    outline:none;
    text-align:center;

    @media (max-width: 1000px){
        width:70%;        
        }
`

export const LoginButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 40px;
    background-color: #275224;
    border: none;
    margin: 10px;
    border-radius: 20px;
    color: #FFF;
    cursor: pointer;
    font-size: 16px;

    @media (max-width: 1000px){
        width:70%;        
        }
`

export const StatusSpan = styled.span`
    display: flex;
    white-space: nowrap
`

export const Spinner = styled.div`
    display:flex;
    justify-content:center;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    
    @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      } 

`

export const ModalContainer = styled.div`
    width: 100%;
    height: 260px;
    max-width: 340px;
    text-align: center;
    background: #275224;
    border-radius: 7px;
    box-shadow: 2px 2px 5px;
`

export const ModalTitle = styled.p`
      color: white;
      font-size: 22px;
      padding-top: 12px;
      padding bottom: 12px;
`

export const ModalBodyParagraph = styled.p`
      color: white;
      font-size: 14px;
      text-align: center;
`

export const ModalButton = styled.button`
      width: 80%;
      height: 33px;
      font-size: 14px;
      cursor: pointer;
      margin: 5px auto;
            margin-top: 1vh;
            border: 0;

            height: 44px;
            background: #0aa050;
            width: 200px;
            font-size: 17.5px;
            color: white;
            font-weight: bold;
            color: #fff;
            border-radius: 4px;
            font-size: 16px;
            transition: background 0.4s;

            &:hover {
                background: #0aa05080;
            }
      
`

export const ModalForm = styled.form`
    display: flex;
    flex-direction: column;
`

export const PrivacyPolicy = styled.div`

`